import React from 'react';
import {ArrowRightIcon} from '@heroicons/react/24/outline';

interface ButtonProps {
    onClick?: () => void;
    text?: string;
    className?: string;
    disabled?: boolean;
    alt?:string;
}

const ButtonSecondary: React.FC<ButtonProps> = ({ onClick, text = "Process (10 Credits)", className, alt:string, disabled = false }) => {
    const baseStyle = "font-gilroy w-fit max-w-[300px] font-medium min-w-[124px] text-sm px-4 h-10 rounded-lg flex items-center justify-center gap-1 cursor-pointer";

    let specificStyle;
    let icon = null;

    if (disabled) {
        specificStyle = "bg-slate-500 text-white cursor-not-allowed";
        if (text === "Process (10 Credits)" || text === "Download") {
            icon = <ArrowRightIcon className="h-4 w-4 stroke-2" />;
        }
    } else {
        switch (text) {
            case "View PRD":
                specificStyle = "border-[1px] border-slate-900 bg-transparent text-slate-900 hover:bg-slate-900 hover:text-white";
                break;
            case "View TS":
                specificStyle = "bg-blue text-white hover:bg-blue/80";
                break;
            case "View Tasks":
                specificStyle = "bg-purple text-white hover:bg-purple/80";
                break;
            default:
                specificStyle = "bg-slate-900 text-white hover:bg-slate-800";
                break;
        }
    }

    return (
        <button
            onClick={onClick}
            className={`${baseStyle} ${specificStyle} ${className}`}
            disabled={disabled}
        >
            {text === "Process (10 Credits)" || text === "Download" || text ==='Complete' || text === 'Try Again' ? (
                <>
                    {text}
                    {icon}
                </>
            ) : (
                <>
                    {icon}
                    {text}
                </>
            )}
        </button>
    );
};

export default ButtonSecondary;
