import React from "react";
import {PaymentCancelIcon} from "../../../components/icons/payment/PaymentCancel";
import Button from "../../../components/base/Button";
import {Link} from 'react-router-dom';

export const PaymentCanceled = () => {
    return (
        <div className="w-full h-full bg-white justify-center items-center flex flex-col">
            <div className="bg-white rounded-lg py-10 px-4 flex flex-col font-gilroy max-w-lg">
                <div className="flex flex-col justify-center items-center gap-4">
                    <PaymentCancelIcon/>
                    <h3 className='font-bold text-2xl text-slate-800'>Checkout Canceled</h3>
                    <p className='text-base text-slate-800 text-center'>You've canceled the checkout process. If there's a specific reason you'd like us to know, please feel free to <a className='underline' href='https://www.signlz.co/feedback' target='_blank' rel='noopener noreferrer'>contact us</a> for support.</p>
                    <Link to="/dashboard">
                        <Button text="Go home"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default PaymentCanceled;
