import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import LeftNavbar from "../../components/common/navigation/innerNav";
import React, {useEffect} from "react";
import prdCoverImage from "../../assets/images/what-is-prd.jpg"
import prioritizeCoverImage from "../../assets/images/prioritize.jpg"
import decomposeCoverImage from "../../assets/images/decompose.jpg"
import {useRef} from "react";
import FeedbackButton from "../../components/base/FeedbackButton";
// @ts-ignore
import mixpanel from "mixpanel-browser";
export function ProTips() {
    const sectionRefs = {
        'what-is-good-prd': useRef<HTMLDivElement>(null),
        'prioritization-frameworks': useRef<HTMLDivElement>(null),
        'from-prd-to-tasks': useRef<HTMLDivElement>(null),
    };
    useEffect(() => {
        mixpanel.track("Pro Tips Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);

    return <SidebarLayout>

        <div className='flex flex-row gap-80 max-sm:gap-0 max-sm:px-3'>
            <div className='flex flex-row'>
                <div className='mt-8 ml-14 fixed hidden md:block'>
                    <LeftNavbar sectionRefs={sectionRefs} />
                </div>
            </div>
            <div className='max-w-3xl mt-8 max-sm:px-3'>
                <div className='flex flex-col gap-8 mb-10'>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Pro Tips</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Discover the stuff we love</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'  id='what-is-good-prd'>
                        <h3 className='font-gilroy font-bold text-2xl text-slate-900'>Unveiling the Anatomy of a Good
                            PRD: The Cornerstone of Product Development</h3>

                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            The Product Requirement Document, commonly referred to as PRD, is the very blueprint upon
                            which great products are built. It's the bridge between ideation and creation, between
                            vision and reality. A well-crafted PRD doesn't just list out product requirements; it tells
                            a story, paints a picture, and aligns an entire team towards a unified goal.
                        </p>
                        <img src={prdCoverImage} alt='Great product requirement document.'/>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            In today's fast-paced tech environment, the significance of a comprehensive and clear PRD
                            has never been more paramount. But what truly defines a 'good' PRD? Let's unpack this.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>The Birthplace of Objectives</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Every PRD begins with an objective. This isn't merely a statement of what the product will
                            do, but a deeper introspection into why it should exist in the first place. Is there a
                            market gap? A user need unmet? The objective sets the stage, providing a North Star for all
                            subsequent actions. It also serves as a constant reminder of the product's purpose, ensuring
                            alignment even when the going gets tough.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>The Tapestry of Comprehensive
                            Details</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            A good PRD is meticulous. It delves into the minutiae, ensuring that every aspect of the
                            product, no matter how tiny, is given its due attention. This is where the PRD transitions
                            from the 'why' to the 'how'. It's a delicate balance to strike – being comprehensive without
                            being overwhelming. It's not about inundating the team with information but equipping them
                            with the right amount to execute with clarity.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>The Heartbeat: User Stories</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            At its core, every product is about its user. And a PRD that loses sight of this is akin to
                            a ship without its compass. User stories infuse life into a PRD. They provide context,
                            shaping features around real-world scenarios. They enable developers and designers to step
                            into the shoes of the user, experiencing their pain points, aspirations, and journey. In
                            doing so, user stories ensure that the end product is not just functional but empathetic.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Visual Aids: The Unsung Heroes</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Words, no matter how eloquent, have their limitations. Enter visual aids. Wireframes,
                            mockups, flowcharts – these are the tools that translate abstract ideas into tangible form.
                            They provide a visual representation, a prototype of the dream. For the designer, it's a
                            canvas; for the developer, a roadmap; and for the stakeholder, a sneak peek into the future.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Building with Tomorrow in Mind:
                            Scalability</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Products evolve. What starts as a single feature might soon grow into a suite. A good PRD is
                            built with this evolution in mind. It's forward-looking, laying the groundwork for
                            scalability. This isn't about predicting the future but creating a flexible foundation. It's
                            about ensuring that as the product grows, it doesn't outgrow its core essence but instead
                            builds upon it.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>The Feedback Loop: The Lifeline of
                            Iteration</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            No PRD, no matter how well-crafted, is set in stone. The real world is the ultimate testing
                            ground, and feedback is its voice. A good PRD is iterative. It listens, learns, and evolves.
                            It adapts to feedback, ensuring that the product remains aligned with user needs and market
                            dynamics. This feedback loop is what separates a static document from a living, breathing
                            PRD.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Conclusion</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Crafting a good PRD is an art as much as it is a science. It's a symphony of clarity,
                            detail, empathy, and foresight. In the ever-evolving world of technology, the PRD stands
                            tall as the beacon guiding products from ideation to fruition. It's the unsung hero behind
                            every successful product, the foundation upon which dreams are built and realized.
                        </p>
                    </div>
                    <hr></hr>
                    <div className='flex flex-col gap-4' id='prioritization-frameworks'>
                        <h3 className='font-gilroy font-bold text-2xl text-slate-900'>Demystifying Product
                            Prioritization Frameworks</h3>

                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Product prioritization stands at the intersection of strategy and execution in the realm of
                            product management. It's the meticulous process of deciding the sequence and emphasis of
                            product development to achieve overarching business objectives. Various frameworks have been
                            introduced to streamline this process, each with its unique perspective. Let's delve into
                            some of the most influential ones.
                        </p>
                        <img src={prioritizeCoverImage} alt='Prioritization of tickets with different techniques.'/>
                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>RICE Model</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            RICE, an acronym for Reach, Impact, Confidence, and Effort, is a quantitative approach to
                            product prioritization. Each factor is assigned a score, and the combined result dictates
                            the priority: </p>

                            <ul className="list-disc pl-5">
                                <li><strong>Reach:</strong> How many users will this feature or improvement impact over
                                    a specific time frame?
                                </li>
                                <li><strong>Impact:</strong> To what extent will this affect the user's experience?</li>
                                <li><strong>Confidence:</strong> How certain are we about the reach, impact, and effort
                                    estimates?
                                </li>
                                <li><strong>Effort:</strong> How much effort will it take to implement this feature?
                                </li>
                            </ul>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            By multiplying Reach, Impact, and Confidence, and then dividing by Effort, teams can derive
                            a RICE score, providing a clear hierarchy of features or improvements to tackle.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>MoSCoW Method</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            The MoSCoW method categorizes features or requirements into four buckets: </p>

                            <ul className="list-disc pl-5">
                                <li><strong>Must have:</strong> Non-negotiables that are critical for the project's
                                    success.
                                </li>
                                <li><strong>Should have:</strong> Important but not critical elements.</li>
                                <li><strong>Could have:</strong> Desirable features that are not necessary.</li>
                                <li><strong>Won't have (this time):</strong> Items that are agreed to be off the table
                                    for the current iteration but might be considered in the future.
                                </li>
                            </ul>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            The MoSCoW method is particularly beneficial for projects with tight deadlines or strict
                            budgets, as it quickly surfaces the most critical requirements.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Kano Model</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            The Kano Model evaluates product features based on how users perceive them and their impact
                            on satisfaction. It categorizes features into: </p>

                            <ul className="list-disc pl-5">
                                <li><strong>Basic Needs:</strong> Fundamental features that, if absent, will cause
                                    dissatisfaction.
                                </li>
                                <li><strong>Performance Needs:</strong> Features that correlate directly with user
                                    satisfaction. The better the performance, the higher the satisfaction.
                                </li>
                                <li><strong>Delighters:</strong> Unexpected or additional features that can
                                    significantly boost satisfaction when present but don't cause dissatisfaction when
                                    absent.
                                </li>
                            </ul>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            By understanding which category a feature falls into, product teams can tailor their efforts
                            to maximize user satisfaction.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Eisenhower Matrix</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Also known as the Urgent-Important Matrix, the Eisenhower Matrix helps prioritize tasks
                            based on their urgency and importance: </p>

                            <ul className="list-disc pl-5">
                                <li><strong>Urgent and Important:</strong> Tasks that need immediate attention and have
                                    significant consequences.
                                </li>
                                <li><strong>Not Urgent but Important:</strong> Tasks that contribute to long-term
                                    objectives but aren't time-sensitive.
                                </li>
                                <li><strong>Urgent but Not Important:</strong> Tasks that demand immediate attention but
                                    don't significantly contribute to long-term objectives.
                                </li>
                                <li><strong>Neither Urgent nor Important:</strong> Low-priority tasks that might be
                                    better delegated or even dropped.
                                </li>
                            </ul>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            This matrix is particularly useful in time management, helping product managers identify
                            what truly requires their focus.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Conclusion</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Product prioritization is as much an art as it is a science. While these frameworks provide
                            structured approaches, they thrive in the hands of product managers who can blend data with
                            intuition, strategy with empathy. The ultimate goal remains the same: to create products
                            that resonate, that solve real problems, and that stand the test of time. The journey to
                            that goal, though paved with decisions and trade-offs, becomes more navigable with these
                            frameworks as guiding lights.
                        </p>
                    </div>
                    <hr></hr>
                    <div className='flex flex-col gap-4' id='from-prd-to-tasks'>
                        <h3 className='font-gilroy font-bold text-2xl text-slate-900'>From PRD to Tasks: The Art of
                            Decomposition</h3>

                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            A Product Requirement Document (PRD) serves as the strategic blueprint for product teams.
                            However, transforming this high-level vision into actionable tasks demands a structured and
                            meticulous process. Let's embark on the journey from PRD to tasks, understanding the nuances
                            of decomposing a PRD into epics, user stories, and sub-tasks.
                        </p>
                        <img src={decomposeCoverImage} alt='Decomposition of task! A detailed way to success'/>
                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Understanding the Hierarchical
                            Structure</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            The transformation of a PRD into actionable tasks follows a hierarchical structure: </p>

                            <ul className="list-disc pl-5">
                                <li><strong>Epics:</strong> These are large bodies of work that can be broken down into
                                    several smaller tasks. Epics capture overarching goals that span multiple sprints or
                                    even releases.
                                </li>
                                <li><strong>User Stories:</strong> Derived from epics, user stories are short, simple
                                    descriptions of a feature or function, told from the perspective of the end-user.
                                </li>
                                <li><strong>Sub-tasks:</strong> These are the smallest unit tasks that are derived from
                                    user stories. Each sub-task is a specific action item necessary to achieve the
                                    associated user story.
                                </li>
                            </ul>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Transitioning from PRD to
                            Epics</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Begin the decomposition process by distilling the PRD's high-level objectives into epics. At
                            this stage, it's crucial to stay focused on the broader goals rather than delving into
                            details. Epics should encapsulate the major themes or functionalities that the product aims
                            to achieve. Collaborate with stakeholders, including designers, developers, and marketers,
                            to ensure that epics capture the product's holistic vision.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Breaking Down Epics into User
                            Stories</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Once epics are established, the next step is to break them down into user stories. This
                            phase demands a user-centric approach. Dive into the user's psyche, understanding their
                            needs, challenges, and aspirations. Craft stories that encapsulate these insights. The key
                            is to remain concise, ensuring that each story focuses on a single functionality or benefit.
                            Utilize the classic format: "As a [type of user], I want [a goal] so that [benefit/a
                            reason]." This structure keeps the user's needs at the forefront, ensuring that the product
                            remains user-centric.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Decomposing User Stories into
                            Sub-tasks</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            With user stories in place, the final step is to decompose them into tangible sub-tasks.
                            This is where the rubber meets the road. Sub-tasks translate user stories into actionable
                            steps that can be assigned to team members. Whether it's designing a user interface, writing
                            a piece of code, or conducting user testing, each sub-task should have a clear owner and a
                            defined deadline. Employ tools like JIRA or Trello to track these sub-tasks, ensuring
                            transparency and accountability.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>The Role of Collaboration</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            While the process of decomposition follows a structured path, collaboration is its
                            lifeblood. At each step, engage with cross-functional teams. Hold brainstorming sessions,
                            seek feedback, and ensure that every voice is heard. This collaborative spirit not only
                            ensures that the PRD is accurately decomposed but also fosters a sense of ownership among
                            team members.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Iterative Refinement</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Decomposition is not a one-time event. As the product evolves, revisit the epics, user
                            stories, and sub-tasks. Refine them based on new insights, changing market dynamics, or user
                            feedback. This iterative approach ensures that the product remains aligned with the original
                            vision while being adaptable to change.
                        </p>

                        <h4 className='font-gilroy font-bold text-xl text-slate-900'>Conclusion</h4>
                        <p className='font-gilroy font-normal text-slate-900 text-base'>
                            Decomposing a PRD into epics, user stories, and sub-tasks is a journey of transformation.
                            It's about taking a strategic vision and molding it into actionable steps. While frameworks
                            provide a roadmap, the true magic lies in collaboration, user-centricity, and adaptability.
                            It's this meticulous and iterative process that transforms a PRD from a document on paper
                            into a living, breathing product in the hands of users.
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <FeedbackButton/>
    </SidebarLayout>;
}
