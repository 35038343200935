import React, { useState, useEffect } from 'react';
import {ChevronUpIcon, ChevronRightIcon} from '@heroicons/react/24/solid';
import {userStoryIcon} from "../icons/userStory";
import {subTaskIcon} from "../icons/subTask";
import {CloseIcon} from "../icons/close";


const Sidebar: React.FC<{ subTask: SubTask | null; close: () => void }> = ({subTask, close}) => {
    const sidebarRef = React.useRef<HTMLDivElement>(null);

    // Close sidebar when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [close]);


    if (!subTask) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
            <div ref={sidebarRef} className={`fixed right-0 top-0 bottom-0 bg-white shadow-sm w-fit p-8 z-50 transition-transform duration-300 ease-in-out ${subTask ? 'transform translate-x-0' : 'transform translate-x-full'}`}>
                <div className='flex flex-col gap-3'>
                    <div className="w-96 h-8 justify-between items-center gap-4 inline-flex">
                        <div className="justify-start items-center gap-[9px] flex">
                            <div className="bg-sky-400 rounded-md justify-start items-start gap-[15px] flex">
                                {subTaskIcon()}
                            </div>
                            <div className="text-slate-900 text-base font-bold font-['Gilroy'] leading-tight">{subTask?.title}</div>
                        </div>
                        <button onClick={close}><CloseIcon/></button>
                    </div>
                    <hr/>
                    <div className="w-fit bg-white flex-col font-gilroy justify-start items-start gap-2.5 inline-flex">
                        <div className="flex-col justify-start items-start gap-2 flex">
                            <div
                                className="text-slate-500 text-sm font-normal font-['Gilroy'] leading-tight">Description
                            </div>
                            <div
                                className="w-[381px] text-slate-800 text-base font-medium font-gilroy leading-tight">{subTask?.description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface SubTask {
    title: string;
    description: string;
}

interface UserStory {
    title: string;
    description: string;
    story_points: number;
    'sub-tasks'?: SubTask[];
}

interface Task {
    title: string;
    description: string;
    user_stories: UserStory[];
}

interface AccordionProps {
    task: Task;
    index: number;
    epicName: string;
}

const Accordion: React.FC<AccordionProps> = ({task, index, epicName}) => {
    const [openStories, setOpenStories] = useState<number[]>([]);
    const userStories = task.user_stories;
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [selectedSubTask, setSelectedSubTask] = useState<SubTask | null>(null);
    const [activeSubTaskIndex, setActiveSubTaskIndex] = useState<number | null>(null);

    const openSidebar = (subTask: SubTask, index: number) => {
        setSelectedSubTask(subTask);
        setActiveSubTaskIndex(index);  // Update the active index
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSelectedSubTask(null);
        setActiveSubTaskIndex(null);  // Reset the active index
        setSidebarOpen(false);
    };
    const toggleAccordion = (id: number) => {
        setOpenStories((prevOpenStories) => {
            if (prevOpenStories.includes(id)) {
                return prevOpenStories.filter((storyId) => storyId !== id);
            } else {
                return [...prevOpenStories, id];
            }
        });
    };
    const truncatedEpicName = epicName.length > 23 ? epicName.substring(0, 23) + "..." : epicName;

    return (
        <div>
            {userStories.map((userStory, storyIndex) => (

                <div key={storyIndex}>
                    <div onClick={() => toggleAccordion(storyIndex)}
                         className="w-full h-12 p-3 rounded-lg border border-slate-200 flex-col justify-center items-end gap-2.5 inline-flex mb-2 cursor-pointer hover:bg-slate-50">
                        <div className="w-full justify-between items-center inline-flex">
                            <div className='flex flex-row gap-3 items-center'>
                                {userStoryIcon()}
                                <div className="text-black text-[15px] font-normal font-['Gilroy'] leading-none"
                                     title={userStory.title}>{userStory.title.length > 75 ? userStory.title.substring(0, 75) + "..." : userStory.title}</div>
                            </div>
                            <div className="justify-center items-center gap-4 flex">
                                <div
                                    className='flex justify-center items-center px-1 py-1 bg-purple/20 rounded-lg text-center'
                                    title={epicName}>
                                    <h5 className='font-gilroy font-semibold text-[11px] text-darkPurple uppercase'>{truncatedEpicName}</h5>
                                </div>
                                <div
                                    className="px-1 py-0.5 bg-slate-300 rounded-[40px] flex-col justify-center items-center gap-2.5 inline-flex">
                                    <div
                                        className="text-black text-xs font-semibold font-['Gilroy'] leading-3">{userStory["sub-tasks"]?.length}</div>
                                </div>
                                {openStories.includes(storyIndex) ?
                                    <ChevronUpIcon className="h-4 w-4 stroke-slate-400 stroke-2"/> :
                                    <ChevronRightIcon className="h-4 w-4 stroke-slate-400 stroke-2"/>}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`overflow-hidden transition-all ease-in-out duration-300 ${openStories.includes(storyIndex) ? 'max-h-[1000px]' : 'max-h-0'}`}>
                        {userStory["sub-tasks"]?.map((subTask, index) => (
                            <div key={index}
                                 className={`w-full h-12 p-3 rounded-lg bg-slate-100 border border-slate-200 flex-col justify-center items-end gap-1 inline-flex mb-2 cursor-pointer hover:bg-slate-300 ${activeSubTaskIndex === index ? 'bg-slate-300' : ''}`}
                                 onClick={() => openSidebar(subTask, index)}>
                                <div className="w-full justify-between items-center inline-flex">
                                    <div className='flex flex-row gap-3 items-center'>
                                        {subTaskIcon()}
                                        <div
                                            className="text-black text-[15px] font-normal font-['Gilroy'] leading-none">{subTask.title}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            ))}
            <Sidebar subTask={selectedSubTask} close={closeSidebar}/>
        </div>
    );
};

export default Accordion;
