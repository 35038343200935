export const AppRoutes = {
    // Authentication
    AUTH_LOGIN: "/auth/login",
    AUTH_SIGNUP: "/auth/signup",
    AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
    AUTH_RESET_PASSWORD: "/auth/set-password/:token",
    AUTH_LOGOUT: "/auth/logout",
    AUTH_VERIFY_EMAIL: 'verify-email/:token',
    AUTH_VERIFY_YOUR_EMAIL: '/verify-your-email',
    AUTH_GOOGLE: '/google/auth',

    // AI Dashboard
    HOME_INDEX: "/dashboard",
    GENERATE_PRD_INDEX: "/dashboard/generate-prd",
    IMPROVE_PRD_INDEX: "/dashboard/improve-prd",
    ADD_FEATURE_INDEX: "/dashboard/add-feature",
    GENERATE_TECH_SPEC_INDEX: "/dashboard/generate-techspec",
    GENERATE_TASKS_INDEX: "/dashboard/generate-tasks",


    // My PRDs
    MY_PRDS_INDEX: "/my-prds",
    SINGLE_PRD_INDEX: "/prd/:id",
    EDIT_PRD_INDEX: "/edit-prd/:id",
    SINGLE_TS_INDEX:"/tech-spec/:id",
    EDIT_TS_INDEX: "/tech-spec-edit/:id",
    TASKS_INDEX: "/tasks/:id",

    // Pro-Tips
    PRO_TIPS_INDEX: "/pro-tips",

    // Pricing
    PRICING_INDEX: "/pricing",

    // Payment
    PAYMENT_SUCCESS: "/payment/complete",
    PAYMENT_CANCELED: "/payment/cancel",
    PAYMENT_FAILED: "/payment/fail"

};
