import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Button from "../../components/base/Button";
import FAQitem from "../../components/base/FAQitem";
import FeedbackButton from "../../components/base/FeedbackButton";
import axios from "axios";
import { AuthToken } from "../../service/http/AuthToken";
import { StripeIcon } from "../../components/icons/stripe";
// @ts-ignore
import mixpanel from "mixpanel-browser";
export function Pricing() {
    const authToken = new AuthToken();

    const proceedToCheckout = async (stripePriceId: string) => {
        const url = `${process.env.REACT_APP_API_URL}/stripe/checkout?price=${stripePriceId}`;

        try {
            const response = await axios.post(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );
            console.log("API Response:", response);

            // Redirect the user to the received URL
            if (response.data && response.data.redirect_url) {
                mixpanel.track("Checkout Successful", {
                    "Stripe Price ID": stripePriceId,
                });
                window.location.href = response.data.redirect_url;
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    };

    useEffect(() => {
        mixpanel.track("Pricing Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);

    return (
        <SidebarLayout>
            <div className='mx-auto max-w-5xl mt-8 max-sm:px-4'>
                <div className='flex flex-col gap-8 mb-10'>

                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col'>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Get More Credits</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Choose Your Package and Fuel Your Innovation</p>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row gap-4 md:gap-6'>
                        <div className='w-1/2 max-sm:w-full px-5 py-3 bg-white rounded-lg flex flex-col gap-1'>
                            <div className='flex flex-row w-full justify-between items-center'>
                                <h4 className='font-gilroy font-semibold text-base text-blue bg-gradient-to-r from-orange via-magenta to-darkPurple text-transparent bg-clip-text'>Starter Pack</h4>
                            </div>
                            <h3 className='font-gilroy font-bold text-xl'>100 Credits</h3>
                            <p className='font-gilroy text-sm font-medium text-slate-500'>Generate up to 10 docs</p>
                            <p className='font-gilroy font-normal text-sm'>Start small – dream big! Turn your napkin scribbles into a full-fledged PRD masterpiece!</p>
                            <Button onClick={() => proceedToCheckout("price_1Nz4pqEzQigqyDw2QIdgvGKE")} className='mt-5 w-full max-w-full' text='Get for $4.99'/>

                        </div>
                        <div className='w-1/2 max-sm:w-full px-5 py-3 bg-white rounded-lg flex flex-col gap-1'>
                            <div className='flex flex-row w-full justify-between items-center'>
                                <h4 className='font-gilroy font-semibold text-base bg-gradient-to-r from-orange via-magenta to-darkPurple text-transparent bg-clip-text'>Pro Pack</h4>
                                <div className='px-1 py-1 font-gilroy font-semibold text-xs text-rosei bg-rosei/5 rounded'>SAVE 20%</div>
                            </div>
                            <h3 className='font-gilroy font-bold text-xl'>625 Credits</h3>
                            <p className='font-gilroy text-sm font-medium text-slate-500'>Generate up to 65 docs</p>
                            <p className='font-gilroy font-normal text-sm'>Your ideas are epic, and your credits are, too! Time to launch your PRDs to new heights!</p>
                            <Button onClick={() => proceedToCheckout("price_1O089OEzQigqyDw28rTRm2KQ")} className='mt-5 w-full max-w-full' text='Get for $24.99'/>
                        </div>
                        <div className='w-1/2 max-sm:w-full px-5 py-3 bg-white rounded-lg flex flex-col gap-1'>
                            <div className='flex flex-row w-full justify-between items-center'>
                                <h4 className='font-gilroy font-semibold text-base text-blue bg-gradient-to-r from-orange via-magenta to-darkPurple text-transparent bg-clip-text'>Organization</h4>
                            </div>
                            <h3 className='font-gilroy font-bold text-xl'>Let's Talk</h3>
                            <p className='font-gilroy font-normal text-sm'>Tailored for organizations with grand ambitions in the vast product universe. To build complex documentations.</p>
                            <Link className='w-full' to='https://www.signlz.co/enterprise' target='_blank' rel='noopener noreferrer'>
                                <Button className='mt-5 w-full max-w-full' text='Contact Us' />
                            </Link>
                        </div>
                    </div>
                    <FAQitem />
                    <div className='flex flex-row justify-center items-center font-gilroy'>
                        <p className='font-semibold'>Powered by</p>
                        <StripeIcon />
                    </div>
                </div>
                <FeedbackButton />
            </div>
        </SidebarLayout>
    );
}
