import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import React, {useState, useEffect, useRef} from "react";
import TextArea from "../../components/base/TextArea";
import Lottie from 'lottie-react';
import lottieJson from '../../assets/signlzMaskot.json';
import Button from "../../components/base/Button";
import {useNavigate, useLocation, Link} from 'react-router-dom';
import axios from 'axios';
import Banner from "../../components/base/Banner";
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import lottiePRDAnimation from "../../assets/doc_loader.json";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
// @ts-ignore
import mixpanel from "mixpanel-browser";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import InputField from "../../components/base/InputField";


export function ImprovePRD() {
    const [textareaValue, setTextareaValue] = useState("");
    const [buttonText] = useState("Improve PRD (10 Credits)");
    const [divText, setDivText] = useState("Let me help you to improve your existing product requirements. Just paste your PRD text.");
    const [showTextArea, setShowTextArea] = useState(true);
    const [prdId, setPrdId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [typewriterIndex, setTypewriterIndex] = useState(0);
    const [prdStatus, setPrdStatus] = useState("");  // New state variable to track PRD status
    const navigate = useNavigate();  // Initialize useHistory
    const [initialLoading, setInitialLoading] = useState(false);
    const [stopAnimation, setStopAnimation] = useState(false);
    const [presetText, setPresetText] = useState<string | null>(null);
    const [showPresetDivs, setShowPresetDivs] = useState(true);
    const [showBanner, setShowBanner] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const location = useLocation();
    const initialText = (location.state as any)?.initialText;
    const draftPrdId = new URLSearchParams(location.search).get('prdId');
    const authToken = new AuthToken();
    const [retryCount, setRetryCount] = useState(0);
    const [improvements, setImprovements] = useState([]);

    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const [prdText, setPrdText] = useState('');
    const [documentTitle, setDocumentTitle] = useState("");

    // Function to handle changes in the document title input
    const handleDocumentTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentTitle(event.target.value);
    };


    // Initialize the checked state when improvements are set
    useEffect(() => {
        if (improvements.length) {
            setCheckedState(new Array(improvements.length).fill(true));
        }
    }, [improvements]);

    // Handler for checkbox change with TypeScript typing
    const handleCheckboxChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
    };


    const maxRetries = 3; // Set a maximum number of retries
    const loadingSentences = [
        "Got it... give me a moment to digest it.",
        "Initializing Process... Setting Things up for you.",
        "Rolling up my digital sleeves...",
        "Gathering required information... Making sure I have what I need.",
        "Your boyfriend Is cheating on you! Oh no. This message was for my sister.",
        "Running quality checks... Checking Everything Twice for Accuracy.",
        "Doing some heavy lifting... But I've Been to the gym, so it's all under control.",
        "Assembling components... We're halfway there!",
        "Planting the seeds of success... Watering with care.",
        "Almost there! Preparing some questions we need to clarify and review before we proceed.",
        "We're on the final stretch! Remember, Signlz thrives when you share the details that matter most. Your input powers our precision!",
        "Don't refresh the page, we are almost there... Sometimes it takes more time to process your request.   "
    ];
    const [showLoadingText, setShowLoadingText] = useState(false);

    const stopAnimationRef = useRef(false);
    const [showEssenceCapturedMessage, setShowEssenceCapturedMessage] = useState(false);
    const typeSentence = async (sentence: string, isLastSentence: boolean = false, delay: number = 75, onStop?: () => void) => {
        for (let i = 0; i < sentence.length; i++) {
            if (stopAnimationRef.current) {
                onStop?.(); // Call the onStop callback if provided
                return; // If stopAnimation is true, exit the function
            }
            setDivText(sentence.substring(0, i + 1));

            await new Promise<void>(resolve => {
                const timeoutId = setTimeout(() => {
                    resolve();
                    clearTimeout(timeoutId);
                }, delay);
            });
        }
        if (!isLastSentence) {
            // Wait for a second before clearing the text, unless stopAnimation is true
            await new Promise<void>(resolve => {
                const timeoutId = setTimeout(() => {
                    if (!stopAnimation) {
                        setDivText("");
                    } else {
                        onStop?.(); // Call the onStop callback if provided
                    }
                    resolve();
                    clearTimeout(timeoutId); // Clear the timeout after it completes
                }, 1000);
                if (stopAnimationRef.current) {
                    clearTimeout(timeoutId);
                }
            });
        }
    };

    // Update the stopTyping function
    const stopTyping = (message: string = "") => {
        if (!stopAnimationRef.current) {
            stopAnimationRef.current = true;
            if (message) {
                setDivText(message);
                setShowEssenceCapturedMessage(true); // Set the flag to true when the message is shown
            }
        }
    };

    useEffect(() => {
        if (prdStatus === "suggesting_improvements_completed") {
            setShowEssenceCapturedMessage(true);
            setShowPresetDivs(false);
            // Assuming you want to clear any previous message in divText
            setDivText("Please review the list of suggestions and chose which you would like to apply.");
        }
    }, [prdStatus]);


    const playLoadingSentences = async () => {
        for (let i = 0; i < loadingSentences.length; i++) {
            const isLastSentence = i === loadingSentences.length - 1;
            await typeSentence(loadingSentences[i], isLastSentence, 75, () => {
                // This callback gets called if the animation is stopped
                setDivText(""); // Clear the text immediately
            });
            if (stopAnimation) {
                break; // Exit the loop if animation is stopped
            }
        }
    };

    const handleTextareaChange = (value: string) => {
        setTextareaValue(value);
    };

    // Function to make initial request and handle retries
    const makeInitialRequest = async (prdText: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/create`,
                null,
                {
                    params: {
                        prd_text: prdText,
                        prd_title: documentTitle
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                const data = response.data;
                setPrdId(data.prd_id);
                // Call the new function to suggest improvements
                suggestImprovements(data.prd_id, prdText);
            } else {
                console.error('API did not return success.');
                // Handle error...
            }
        } catch (error) {
            console.error('Error while making initial request:', error);
            // Handle error...
        }
    };

    // New function to suggest improvements
    const suggestImprovements = async (prdId: string, prdText: string) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${prdId}/suggest_improvements`,
                null,
                {
                    params: {prd_text: prdText},
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );
            setStopAnimation(false);
            setShowLoadingText(true);
            playLoadingSentences().catch();
            monitorStatus(prdId);
        } catch (error) {
            console.error('Error while suggesting improvements:', error);
            // Handle error...
        }
    };

    const monitorStatus = async (prdId: string) => {
        let intervalId = setInterval(async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${prdId}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });

                const data = response.data;
                if (data.prd.status === "suggesting_improvements_completed") {
                    clearInterval(intervalId);
                    setShowLoadingText(false);
                    stopTyping();
                    setPrdStatus(data.prd.status);
                    setImprovements(data.prd.prd_improvements.suggestions);
                    setPrdText(data.prd.prd_text);
                    console.log("Improvements set:", data.prd.prd_improvements.suggestions);
                }
            } catch (error) {
                console.error("Error while monitoring status:", error);
                clearInterval(intervalId);
            }
        }, 5000); // Check every 5 seconds
    };

    const applyPRDImprovements = async () => {
        try {
            const selectedSuggestions = improvements.filter((_, index) => checkedState[index]);
            await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${prdId}/apply_prd_improvements`,
                null,
                {
                    params: {
                        prd_text: prdText,
                        prd_improvements: selectedSuggestions.join(';') // Assuming each suggestion is a string
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );
            navigate('/my-prds'); // Redirect to /my-prds on success
        } catch (error) {
            console.error('Error applying PRD improvements:', error);
            // Handle error...
        }
    };


    useEffect(() => {
        if (initialText) {
            setTextareaValue(initialText);
        }
    }, [initialText]);
    const handleButtonClick = async () => {
        const initialRequestText = textareaValue || presetText;
        // Check if both textarea and document title have values
        if (!initialRequestText || !documentTitle) {
            console.error('No input provided.');
            return;
        }
        setStopAnimation(false);
        setShowTextArea(false); // Hide the textarea
        setIsLoading(true);
        setShowBanner(false);  // Hide the banner initially

        if (buttonText === "Improve PRD (10 Credits)" && !prdId) {
            await makeInitialRequest(initialRequestText);
        }

        setIsLoading(false);
    };


    useEffect(() => {
        if (typewriterIndex < loadingSentences.length && isLoading) {
            const isLastSentence = typewriterIndex === loadingSentences.length - 1;
            typeSentence(loadingSentences[typewriterIndex], isLastSentence).then(() => {
                if (!isLastSentence) {
                    setTypewriterIndex(typewriterIndex + 1);
                }
            });
        }
    }, [typewriterIndex]);

    useEffect(() => {
        if (presetText && !prdId) { // Only call handleButtonClick if there's no prdId set
            handleButtonClick().catch();
        }
    }, [presetText, prdId]); // Add prdId as a dependency
    useEffect(() => {
        mixpanel.track("Improve PRD Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);


    if (initialLoading) {
        return <SidebarLayout>
            <div className='mx-auto max-w-xs mt-8 font-gilroy max-sm:px-4 text-lg font-bold'>
                <Lottie animationData={lottiePRDAnimation}/>
            </div>
        </SidebarLayout>;
    }

    return (
        <SidebarLayout>
            <FeedbackButton/>
            {showBanner && <Banner/>}
            <div className='mx-auto max-w-5xl mt-8 max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10'>
                    <div className='flex flex-row gap-2 items-center'>
                        <Link to='/dashboard' className='flex flex-row gap-2 items-center'>
                            <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                            <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to AI
                                Dashboard</h6>
                        </Link>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Improve Existing PRD</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Provide your existing PRD
                                text</p>
                        </div>
                        <div
                            className='flex flex-row gap-3 max-w-5xl h-16 rounded-lg bg-slate-900 items-center px-3 py-2'>
                            <Lottie animationData={lottieJson} className='w-10 h-10'/>
                            <p className='font-gilroy font-light text-white text-base max-sm:text-sm'>{divText}</p>
                            {showEssenceCapturedMessage && (
                                <p className='font-gilroy font-light text-white text-base max-sm:text-sm'>

                                </p>
                            )}
                        </div>
                        {showLoadingText && (
                            <p className='font-gilroy font-normal text-lg text-slate-600 max-sm:text-sm'>
                                Based on your requirements text, I'm preparing a set of suggestions for improvements.
                                Please don't refresh.
                            </p>
                        )}

                    </div>
                    {showTextArea && (
                        <InputField
                            className='text-lg pl-5 border-none shadow placeholder:text-base placeholder-slate-500'
                            placeholder='Document title'
                            value={documentTitle}
                            onChange={handleDocumentTitleChange}
                            showLabel={false}
                            required
                        />
                    )}
                    {showTextArea && (
                        <div className='bg-white flex flex-col items-end shadow rounded-lg p-3'>
                            <TextArea
                                showCharCount={false}
                                isUnlimited={true}
                                className='border-none'
                                placeholder='Paste your requirements text here...'
                                value={textareaValue}
                                onChange={handleTextareaChange}/>
                            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
                            <Button text={buttonText} onClick={handleButtonClick} disabled={textareaValue === "" || documentTitle === ""}/>
                        </div>
                    )}

                    {prdStatus === "suggesting_improvements_completed" && (
                        <div className="flex flex-col gap-12 mt-4">
                            <div className="flex flex-row gap-4">
                                {/* Container for the PRD text */}
                                {prdText && (
                                    <div className='flex-1' style={{ maxHeight: '500px' }}> {/* Set a common max height */}
                                        <h2 className="text-2xl font-gilroy font-bold mb-4">Your PRD Text</h2>
                                        <div className="bg-white rounded-lg shadow-md p-4 prose overflow-auto" style={{ height: '100%' }}>
                                            <ReactMarkdown remarkPlugins={[remarkGfm]} className="markdown font-gilroy">
                                                {prdText}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                )}

                                {/* Container for the suggestions */}
                                <div className="flex-1" style={{ maxHeight: '500px' }}> {/* Set a common max height */}
                                    <h2 className='text-2xl font-gilroy font-bold mb-4'>Improvement Suggestions</h2>
                                    <div className="overflow-auto" style={{ height: '100%' }}> {/* Enable inner scroll */}
                                        <ul>
                                            {improvements.map((suggestion, index) => (
                                                <li key={index}
                                                    className="bg-white rounded-lg shadow font-gilroy p-4 flex items-start gap-4 mb-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedState[index]}
                                                        onChange={() => handleCheckboxChange(index)}
                                                        className="form-checkbox h-5 w-5 text-blue rounded cursor-pointer mt-5"
                                                    />
                                                    <TextArea
                                                        value={suggestion}
                                                        className='min-h-[150px] ring-1 ring-slate-300 border-none focus:ring-1 focus:ring-blue'
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Button to Apply PRD Improvements */}
                            <div className="flex justify-end mt-4">
                                <Button text="Apply PRD Improvements" onClick={applyPRDImprovements} />
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </SidebarLayout>
    );
}
