import React, { useState } from "react";
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import eye icons

interface InputFieldProps {
    id?: string;
    label?: string;
    extra?: string;
    placeholder?: string;
    variant?: string;
    state?: string;
    disabled?: boolean;
    required?: boolean;
    type?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    showLabel?: boolean;
}

const InputField: React.FC<InputFieldProps> = (props) => {
    const { label, id, extra, type, placeholder, variant, state, disabled, value, onChange, required, className, showLabel = true } = props;
    const [showPassword, setShowPassword] = useState(false);  // Local state to manage password visibility

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Default classes for the input field
    const defaultInputClass = `font-gilroy mt-2 flex h-12 w-full items-center justify-center rounded-lg border bg-white p-3 text-sm outline-none focus:ring-1 focus:ring-blue ${
        disabled === true ? "!border-none !bg-gray-100" : state === "error" ? "border-red-500 text-red-500 placeholder:text-red-500" : state === "success" ? "border-green-500 text-green-500 placeholder:text-green-500" : "border-gray-200"
    }`;

    return (
        <div className={`${extra}`}>
            {showLabel && (
                <label
                    htmlFor={id}
                    className={`font-gilroy text-sm text-brand-900 ${variant === "auth" ? "ml-1.5 font-bold" : "ml-3 font-bold"}`}
                >
                    {label}
                </label>
            )}
            <div className="relative">  {/* Relative positioning for input and icon */}
                <input
                    disabled={disabled}
                    type={showPassword ? "text" : type}  // Dynamically set the type based on showPassword state
                    id={id}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    className={`${defaultInputClass} ${className}`}
                />
                {type === 'password' && (  // Only show icon for password type input
                    <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <FaEyeSlash className='fill-slate-400' /> : <FaEye className='fill-slate-400'/>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputField;
