import React, {useState, useEffect, useRef} from 'react';
import {EllipsisVerticalIcon, ArrowPathIcon, DocumentDuplicateIcon} from '@heroicons/react/24/outline';
import lottieAnimation from "../../assets/signlzLoader.json";
import Lottie from "lottie-react";
import Button from "../../components/base/Button";
import {useNavigate} from 'react-router-dom';
import {PaymentFailIcon} from "../icons/payment/PaymentFail";
import ButtonSecondary from "./ButtonSecondary";

interface CardProps {
    title: string;
    date: string;
    downloadLink?: string;
    status: 'initial_request_completed' | 'generating_prd' | 'improving_prd' | 'completed' | 'processing_initial_request' | 'duplicated' | 'tech_spec_completed' | 'tasks_completed' | 'failed' | 'generating_tasks' | 'initial_request_failed' | 'applying_improvements' | 'adding_feature' ;
    onDownload?: () => Promise<void>;
    onDelete?: () => void;
    prdId: string;
    onViewPRD?: (prdId: string) => void;
    onViewTS?: (prdId: string) => void;
    onViewTasks?: (prdId: string) => void;
}

const Card: React.FC<CardProps> = ({
                                       prdId,
                                       onViewPRD,
                                       onViewTS,
                                       onViewTasks,
                                       title,
                                       date,
                                       downloadLink,
                                       status,
                                       onDownload,
                                       onDelete
                                   }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [counter, setCounter] = useState(0);
    const navigate = useNavigate();  // Initialize useHistory
    const optionsRef = useRef<HTMLDivElement | null>(null);

    const handleTryAgain = () => {
        navigate('/dashboard/generate-prd', { state: { initialText: title } });
    };



    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
                setShowOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleViewPRD = () => {
        navigate(`/prd/${prdId}`);
        if (onViewPRD) {
            onViewPRD(prdId);
        }
    };

    const handleViewTS = () => {
        navigate(`/tech-spec/${prdId}`);
        if (onViewTS) {
            onViewTS(prdId);
        }
    };
    const handleViewTasks = () => {
        navigate(`/tasks/${prdId}`);
        if (onViewTasks) {
            onViewTasks(prdId);
        }
    };

    const handleComplete = () => {
        localStorage.setItem('fromCard', 'true');  // Set the flag
        navigate(`/dashboard/generate-prd?prdId=${prdId}`);  // Existing navigation code
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (status === 'generating_prd' || status === 'improving_prd') {
            timer = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [status]);

    if (status === 'processing_initial_request') {
        return null;
    }
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const initiateDownload = async () => {
        setIsDownloading(true);
        if (onDownload) {
            try {
                await onDownload();
            } catch (error) {
                console.error('Error during download:', error);
            }
        }
        setIsDownloading(false);
        setShowOptions(false);
    };

    const trimTitle = (title: string | undefined) => {
        if (typeof title === 'undefined') return "No Title";
        return title.length > 100 ? title.substring(0, 100) + '...' : title;
    };

    const renderTitle = () => {
        const trimmedTitle = trimTitle(title);

        if (status === 'initial_request_completed') {
            return (
                <span className="text-red-500 font-gilroy">Draft PRD</span>
            );
        } else if (status === 'generating_prd' || status === 'improving_prd' || status === 'applying_improvements' || status === 'adding_feature') {
            const minutes = String(Math.floor(counter / 60)).padStart(2, '0');
            const seconds = String(counter % 60).padStart(2, '0');
            return (
                <div className='flex flex-row justify-between items-center'>
                    <h2 className='font-gilroy font-bold text-base text-slate-900' title={title}>{trimmedTitle}</h2>
                    <span className="text-slate-900 text-sm font-gilroy">
                    In progress: {minutes}:{seconds} / Could take from 30 sec upto 5 mins
                </span>
                </div>
            );
        } else {
            return (
                <div className='flex flex-row items-center'>
                    {status === 'duplicated' && <DocumentDuplicateIcon className='w-6 h-6 text-slate-500 mr-2'/>}
                    <h2 className='font-gilroy font-bold text-base text-slate-900 cursor-pointer hover:underline'
                        title={title} onClick={handleViewPRD}>{trimmedTitle}</h2>
                </div>
            );
        }
    };

    const renderDateOrAnimation = () => {
        if (status === 'generating_prd' || status === 'improving_prd' || status === 'applying_improvements' || status === 'adding_feature') {
            return <div className='flex flex-col'>
                <p className='font-gilroy text-base text-slate-500'>While we're baking your doc, feel free to start
                    another task. We'll notify you by email when it's ready.</p>
                <Lottie animationData={lottieAnimation}/>

            </div>;
        } else {
            return <p className='font-gilroy font-normal text-sm text-slate-400'>{date}</p>;
        }
    };

    const renderIcon = () => {
        if (isDownloading) {
            return <ArrowPathIcon className='w-6 h-6 text-slate-900 animate-spin'/>;
        } else if (status === 'generating_prd' || status === 'improving_prd') {
            return "";
        } else {
            return <EllipsisVerticalIcon
                className='w-6 h-6 stroke-slate-900 stroke-2 hover:bg-slate-100 hover:rounded-full cursor-pointer'/>;
        }
    };

    // Button rendering logic
    const renderButtons = () => {
        if (status === 'tech_spec_completed' || status === 'generating_tasks') {
            return (
                <>
                    <ButtonSecondary
                        className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                        text='View PRD' onClick={handleViewPRD}/>
                    <ButtonSecondary
                        className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                        text='View TS' onClick={handleViewTS}/>
                </>
            );
        } else if (status === 'tasks_completed') {
            return (
                <>
                    <ButtonSecondary
                        className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                        text='View PRD' onClick={handleViewPRD}/>
                    <ButtonSecondary
                        className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                        text='View TS' onClick={handleViewTS}/>
                    <ButtonSecondary
                        className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                        text='View Tasks' onClick={handleViewTasks}/>
                </>
            );
        } else if (status === 'initial_request_completed') {
            return <ButtonSecondary className='w-full sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis' text='Complete'
                           onClick={handleComplete}/>;
        } else if (status === 'generating_prd' || status === 'improving_prd' || status === 'applying_improvements' || status === 'adding_feature') {
            return "";
        } else {
            return <ButtonSecondary
                className='w-auto px-4 min-w-[100px] sm:w-auto whitespace-nowrap overflow-hidden text-ellipsis'
                text='View PRD' onClick={handleViewPRD}/>;
        }
    };
    // @ts-ignore
    if (status === 'processing_initial_request' || status === 'initial_request_failed' ) {
        return null;
    }
    if (status === 'failed') {
        return (
            <div
                className="bg-white w-full px-4 py-4 rounded-lg shadow flex flex-row justify-between items-center relative">
                <div className='flex flex-row items-center gap-2'>
                    <PaymentFailIcon size={40}/>
                    <div className='flex flex-col gap-1'>
                        <h2 className='font-gilroy font-bold text-base text-slate-900'>Sorry, it looks like we've stuck
                            in a mud.</h2>
                        <p className='font-gilroy font-normal text-sm text-slate-400'>{date}</p>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-2'>
                    <Button text='Try Again' onClick={handleTryAgain}/>
                    <EllipsisVerticalIcon
                        className='w-6 h-6 stroke-slate-900 stroke-2 hover:bg-slate-100 hover:rounded-full cursor-pointer' onClick={toggleOptions}/>
                </div>
                {showOptions && (
                    <div ref={optionsRef}
                         className="absolute font-gilroy top-full right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-xl z-10">
                        <div onClick={onDelete}
                             className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 cursor-pointer">
                            Delete
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            className="bg-white w-full px-4 py-4 rounded-lg shadow flex flex-row max-sm:flex-col max-sm:items-start max-sm:gap-8 justify-between items-center relative">
            <div className='w-full flex-grow flex flex-col gap-1'>
                {renderTitle()}
                {renderDateOrAnimation()}
            </div>
            <div className='flex flex-row items-center sm:gap-4 sm:w-auto  justify-end'>
                <div className='flex flex-row items-center gap-2 sm:w-auto' onClick={toggleOptions}>
                    {renderButtons()}
                    {renderIcon()}
                </div>
                {showOptions && (
                    <div ref={optionsRef}
                         className="absolute font-gilroy top-full right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-xl z-10">
                        {(status !== 'initial_request_completed') && downloadLink && (
                            <div onClick={initiateDownload}
                                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                                Download
                            </div>
                        )}
                        <div onClick={onDelete}
                             className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 cursor-pointer">
                            Delete
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
