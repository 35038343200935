import React, { useState, useEffect } from 'react';

type LeftNavbarProps = {
    sectionRefs: {
        [key: string]: React.RefObject<HTMLDivElement>;
    };
};

const LeftNavbar: React.FC<LeftNavbarProps> = ({ sectionRefs }) => {
    const [activeItem, setActiveItem] = useState<string>('what-is-good-prd');

    const items = [
        { name: 'What is good PRD?', anchor: 'what-is-good-prd' },
        { name: 'Prioritization frameworks', anchor: 'prioritization-frameworks' },
        { name: 'From PRD to tasks', anchor: 'from-prd-to-tasks' }
    ];

    useEffect(() => {
        const handleScroll = () => {
            const y = window.scrollY;
            let activeSection = null;

            for (const [section, ref] of Object.entries(sectionRefs)) {
                if (ref && ref.current && y >= ref.current.offsetTop) {
                    activeSection = section;
                }
            }

            if (activeSection) {
                setActiveItem(activeSection);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [sectionRefs]);


    const handleItemClick = (item: any) => {
        setActiveItem(item.anchor);

        const targetElement = document.getElementById(item.anchor);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="relative">
            {/* Gray vertical line covering the entire navbar */}
            <div className="absolute h-full w-0.5 bg-slate-200 left-0 top-0"></div>

            <div className="flex flex-col gap-2">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`flex items-center pl-4 py-2 cursor-pointer relative ${
                            activeItem === item.anchor ? 'before:border-slate-900' : ''
                        } before:border-l-2 before:h-full before:absolute before:top-0 before:left-0`}
                        onClick={() => handleItemClick(item)}
                    >
                        {/* Set text color to slate-500 if not selected */}
                        <span className={`text-base font-gilroy ${activeItem === item.anchor ? 'text-slate-900' : 'text-slate-500'}`}>
                            {item.name}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LeftNavbar;
