import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {Link} from "react-router-dom";

const FeedbackButton: React.FC = () => {
    return (
        <>
            <Link to='https://www.signlz.co/feedback' target='_blank' rel="noopener noreferrer">
        <button
            className="fixed mas-sm:flex font-gilroy font-medium right-0 origin-bottom-right -rotate-90 top-[40%] -translate-y-1/2 bg-white text-slate-800 text-sm rounded-tl-lg rounded-tr-lg px-4 py-2 flex items-center shadow hover:bg-slate-900 hover:text-white max-md:hidden">
            Send Feedback
            <ArrowRightIcon className="w-5 h-5 ml-2 hover:stroke-white" />
        </button>
            </Link>
        </>
    );
};

export default FeedbackButton;
