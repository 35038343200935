import React, {useState} from 'react';
import InputField from "../../components/base/InputField";
import { Link } from "react-router-dom";
import authImg from "../../assets/images/auth.jpg";
import axios from 'axios';
import {SignlzLogoAlpha} from "../../components/icons/logoAlpha";


export const ForgotPassword = () => {
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault(); // prevent the default form submission behavior
        handleResetPassword().catch();
    };
    const handleResetPassword = async () => {
        setLoading(true);
        setMessage(''); // Clear previous messages
        setError(''); // Clear previous errors

        // Check if the email is valid
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
            setError('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset/request`, { email }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                // Instead of displaying the backend message, show the frontend message
                setMessage('Password reset email sent!');
            } else {
                setError(response.data.error || 'An error occurred. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };




    return (
        <div className="h-full w-full overflow-y-auto bg-white">
        <div className="font-gilroy flex h-full w-[35vw] items-center justify-center px-2 max-lg:w-full">
            {/* Forgot Password section */}
            <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <div className='mb-12'><SignlzLogoAlpha/></div>
                <h1 className="mb-2.5 text-3xl font-bold text-brand-900">
                    Forgot Your Password
                </h1>
                <form onSubmit={handleFormSubmit}>
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email"
                    placeholder="Email Address"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    type='submit'
                    className="linear mt-2 w-full rounded-xl bg-slate-800 py-[12px] text-base font-semibold text-white transition duration-200 hover:bg-slate-600 active:bg-slate-700"
                    onClick={handleResetPassword}
                    disabled={loading}

                >
                    {loading ? 'Sending Email...' : 'Reset Password'}
                </button>
                </form>
                {message && <p className="text-slate-900 mt-2 font-gilroy text-sm">{message}</p>}
                {error && <p className="text-red-500 mt-2 font-gilroy text-sm">{error}</p>}
                <div className="mt-12 flex flex-row justify-center">
                    <span className="text-sm font-medium text-slate-800">
                        Didn't want to Reset Password?
                    </span>
                    <Link to="/auth/login" className="ml-1 text-sm font-bold text-brand-900 hover:underline">
                        Log In
                    </Link>
                </div>
            </div>
            <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[65vw] 2xl:w-[65vw]">
                <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                />
            </div>
        </div>
        </div>
    );
}
