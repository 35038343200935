// @ts-ignore
import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const isDebugMode = process.env.REACT_APP_MIXPANEL_DEBUG === 'true';

if (!MIXPANEL_TOKEN) {
    console.error("Mixpanel token is not defined in the environment variables.");
} else {
    // Initialize Mixpanel with conditional debug mode
    mixpanel.init(MIXPANEL_TOKEN, { debug: isDebugMode, persistence: 'localStorage' });
}

export default mixpanel;
