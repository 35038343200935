import React, { useCallback, useEffect } from 'react';
import {ArrowRightIcon, ArrowDownIcon, ArrowPathIcon} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { debounce } from 'lodash';

interface ButtonProps {
    onClick?: () => void;
    text?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    alt?:string;
}

const Button: React.FC<ButtonProps> = ({ onClick, text = "Process (10 Credits)", className, alt:string, disabled = false }) => {
    const baseStyle = "font-gilroy w-fit max-w-[300px] font-medium min-w-[124px] text-sm px-4 h-10 rounded-lg flex items-center justify-center gap-1 cursor-pointer";

    let specificStyle;
    let icon = null;

    if (disabled) {
        specificStyle = "bg-slate-500 text-white cursor-not-allowed";
        if (text === "Process (10 Credits)" || text === "Download") {
            icon = <ArrowRightIcon className="h-4 w-4 stroke-2" />;
        }
    } else {
        switch (text) {
            case "Generate PRD (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Improve PRD (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Generate TS (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Add the Feature (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Generate Tasks (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Regenerate Tasks (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                break;
            case "Regenerate TS (10 Credits)":
                specificStyle = "hover:bg-white hover:border-[1px] hover:border-blue hover:text-black";
                break;
            case "Regenerate PRD (10 Credits)":
                specificStyle = "hover:bg-white hover:border-[1px] hover:border-blue hover:text-black";
                break;
            case "Create New PRD":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                icon = <SparklesIcon className="h-4 w-4" />;
                break;
            case "Process (10 Credits)":
                specificStyle = "bg-gradient-to-r from-blue to-purple text-white hover:bg-gradient-to-r hover:from-purple hover:to-blue";
                icon = <ArrowRightIcon className="h-4 w-4 stroke-2" />;
                break;
            case "Complete":
                specificStyle = "bg-slate-900 text-white hover:bg-slate-800";
                icon = <ArrowRightIcon className="h-4 w-4 stroke-2" />;
                break;
            case "Try Again":
                specificStyle = "bg-slate-900 text-white hover:bg-slate-800";
                icon = <ArrowPathIcon className="h-4 w-4 stroke-2" />;
                break;
            case "Download":
                specificStyle = "bg-slate-900 text-white hover:bg-slate-800";
                icon = <ArrowDownIcon className="h-4 w-4 stroke-2" />;
                break;
            case "Transfer to Jira":
                specificStyle = "bg-blue text-white hover:bg-blue/80";
                break;
            case "View PRD":
                specificStyle = "border-[1px] border-slate-900 bg-transparent text-slate-900 hover:bg-slate-900 hover:text-white";
                break;
            case "View TS":
                specificStyle = "bg-blue text-white hover:bg-blue/80";
                break;
            case "View Tasks":
                specificStyle = "bg-purple text-white hover:bg-purple/80";
                break;
            case "Edit PRD":
                specificStyle = "bg-white text-slate-800 shadow hover:bg-slate-300 hover:text-slate-800";
                break;
            case "Edit TS":
                specificStyle = "bg-white text-slate-800 shadow hover:bg-slate-300 hover:text-slate-800";
                break;
            case "Cancel":
                specificStyle = "bg-white text-slate-800 shadow hover:bg-slate-300 hover:text-slate-800";
                break;
            case "TS Processing":
                specificStyle = "bg-slate-200 text-slate-500 shadow";
                break;
            case "Tasks Processing":
                specificStyle = "bg-slate-200 text-slate-500 shadow";
                break;
            default:
                specificStyle = "bg-slate-900 text-white hover:bg-slate-800";
                break;
        }
    }
    // Debounce the onClick function
    const debouncedOnClick = useCallback(
        debounce(() => {
            if (onClick) {
                onClick();
            }
        }, 300), // 300ms debounce time, adjust as needed
        [onClick]
    );

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            debouncedOnClick.cancel();
        };
    }, [debouncedOnClick]);

    return (
        <button
            onClick={debouncedOnClick}
            className={`${baseStyle} ${specificStyle} ${className}`}
            disabled={disabled}
        >
            {text === "Process (10 Credits)" || text === "Download" || text ==='Complete' || text === 'Try Again' ? (
                <>
                    {text}
                    {icon}
                </>
            ) : (
                <>
                    {icon}
                    {text}
                </>
            )}
        </button>
    );
};

export default Button;
