export function StripeIcon() {
    return (
        <svg width="94" height="44" viewBox="0 0 94 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M82.9251 22.4243C82.9251 17.3618 80.4414 13.3672 75.6943 13.3672C70.9272 13.3672 68.043 17.3618 68.043 22.3847C68.043 28.3371 71.448 31.3429 76.3353 31.3429C78.7188 31.3429 80.5215 30.809 81.8835 30.0575V26.1025C80.5215 26.7748 78.9592 27.1901 76.9762 27.1901C75.0333 27.1901 73.3108 26.5178 73.0905 24.1843H82.885C82.885 23.9272 82.9251 22.8989 82.9251 22.4243ZM73.0304 20.5456C73.0304 18.311 74.4124 17.3816 75.6743 17.3816C76.8961 17.3816 78.198 18.311 78.198 20.5456H73.0304Z" fill="#635BFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M60.3115 13.3672C58.3486 13.3672 57.0867 14.2769 56.3857 14.9097L56.1253 13.6836H51.7188V36.7416L56.7262 35.6935L56.7462 30.0971C57.4673 30.6112 58.5289 31.3429 60.2915 31.3429C63.8768 31.3429 67.1416 28.4953 67.1416 22.2265C67.1216 16.4917 63.8167 13.3672 60.3115 13.3672ZM59.1097 26.9924C57.928 26.9924 57.2269 26.5771 56.7462 26.0629L56.7262 18.7263C57.247 18.1528 57.968 17.7573 59.1097 17.7573C60.9324 17.7573 62.1943 19.7744 62.1943 22.3649C62.1943 25.0148 60.9525 26.9924 59.1097 26.9924Z" fill="#635BFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.8281 12.1994L49.8556 11.1316V7.11719L44.8281 8.16528V12.1994Z" fill="#635BFF"/>
            <path d="M49.8556 13.7031H44.8281V31.0065H49.8556V13.7031Z" fill="#635BFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M39.4399 15.1639L39.1194 13.7005H34.793V31.0039H39.8004V19.2771C40.9822 17.7544 42.9851 18.0313 43.606 18.2488V13.7005C42.9651 13.4632 40.6216 13.0281 39.4399 15.1639Z" fill="#635BFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.4229 9.41016L24.5357 10.4385L24.5156 26.2785C24.5156 29.2052 26.7389 31.3607 29.7033 31.3607C31.3458 31.3607 32.5475 31.0641 33.2085 30.7081V26.6938C32.5676 26.9508 29.4029 27.8605 29.4029 24.9338V17.9135H33.2085V13.7014H29.4029L29.4229 9.41016Z" fill="#635BFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8839 18.7263C15.8839 17.9551 16.5249 17.6584 17.5865 17.6584C19.1087 17.6584 21.0316 18.1133 22.5538 18.924V14.2769C20.8914 13.6243 19.2489 13.3672 17.5865 13.3672C13.5204 13.3672 10.8164 15.4634 10.8164 18.9636C10.8164 24.4216 18.4277 23.5515 18.4277 25.9047C18.4277 26.8144 17.6265 27.111 16.5048 27.111C14.8424 27.111 12.7192 26.4386 11.0367 25.529V30.2355C12.8995 31.0265 14.7823 31.3627 16.5048 31.3627C20.671 31.3627 23.5353 29.3258 23.5353 25.7861C23.5153 19.893 15.8839 20.9411 15.8839 18.7263Z" fill="#635BFF"/>
        </svg>


    )
}
