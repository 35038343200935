import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import React, {useState, useEffect, useRef} from "react";
import TextArea from "../../components/base/TextArea";
import Lottie from 'lottie-react';
import lottieJson from '../../assets/signlzMaskot.json';
import Button from "../../components/base/Button";
import {useNavigate, useLocation, Link} from 'react-router-dom';
import axios from 'axios';
import Banner from "../../components/base/Banner";
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import InputField from "../../components/base/InputField";
// @ts-ignore
import mixpanel from "mixpanel-browser";

export function AddFeature() {
    const [textareaValue, setTextareaValue] = useState("");
    const [showTextArea, setShowTextArea] = useState(true);
    const [prdId, setPrdId] = useState<string | null>(null);
    const navigate = useNavigate();  // Initialize useHistory
    const [presetText, ] = useState<string | null>(null);
    const [showBanner, setShowBanner] = useState(false);
    const [errorMsg] = useState<string | null>(null);
    const location = useLocation();
    const initialText = (location.state as any)?.initialText;
    const authToken = new AuthToken();
    const [documentTitle, setDocumentTitle] = useState("");
    const [featureTitle, setFeatureTitle] = useState("");


    const handleDocumentTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentTitle(event.target.value);
    };
    const handleFeatureTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeatureTitle(event.target.value);
    };



    const handleTextareaChange = (value: string) => {
        setTextareaValue(value);
    };
    const makeInitialRequest = async (prdText: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/create`,
                null,
                {
                    params: {
                        prd_text: prdText,
                        prd_title: documentTitle
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                const data = response.data;
                setPrdId(data.prd_id);
                await addFeature(data.prd_id, prdText);
            } else {
                console.error('API did not return success.');
                // Handle error...
            }
        } catch (error) {
            console.error('Error while making initial request:', error);
            // Handle error...
        }
    };
    const addFeature = async (prdId:string, prdText:string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${prdId}/add_feature`,
                null,
                {
                    params: {
                        prd_text: prdText,
                        feature_request: featureTitle
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate(`/my-prds`); // Redirect to task page with the prdId
                mixpanel.track("Add Feature Start", {
                    "PRD ID": prdId,
                });
            } else {
                console.error('Error in task generation:', response);
                // Handle error...
            }
        } catch (error) {
            console.error('Error while generating tasks:', error);
            // Handle error...
        }
    };


    useEffect(() => {
        if (initialText) {
            setTextareaValue(initialText);
        }
    }, [initialText]);
    const handleButtonClick = async () => {
        const initialRequestText = textareaValue || presetText;
        // Check if both textarea and document title have values
        if (!initialRequestText || !documentTitle) {
            console.error('No input provided.');
            return;
        }

        setShowTextArea(false);
        setShowBanner(false);

        if (!prdId) {
            await makeInitialRequest(initialRequestText);
        }
    };

    useEffect(() => {
        if (presetText && !prdId) { // Only call handleButtonClick if there's no prdId set
            handleButtonClick().catch();
        }
    }, [presetText, prdId]); // Add prdId as a dependency

    useEffect(() => {
        mixpanel.track("Add Feature Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);
    return (
        <SidebarLayout>
            <FeedbackButton/>
            {showBanner && <Banner/>}
            <div className='mx-auto max-w-5xl mt-8 max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10'>
                    <div className='flex flex-row gap-2 items-center'>
                        <Link to='/dashboard' className='flex flex-row gap-2 items-center'>
                            <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                            <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to AI
                                Dashboard</h6>
                        </Link>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Add Feature to PRD</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Describe your feature</p>
                        </div>
                        <div
                            className='flex flex-row gap-3 max-w-5xl h-16 rounded-lg bg-slate-900 items-center px-3 py-2'>
                            <Lottie animationData={lottieJson} className='w-10 h-10'/>
                            <p className='font-gilroy font-light text-white text-base max-sm:text-sm'>Let's break down your requirements into tasks. Just paste your PRD or feature text.</p>
                        </div>
                    </div>
                    <InputField
                        className='text-lg pl-5 border-none shadow placeholder:text-base placeholder-slate-500'
                        placeholder='Document title'
                        value={documentTitle}
                        onChange={handleDocumentTitleChange} // Attach onChange handler
                        showLabel={false}
                        required
                    />
                    <InputField
                        className='text-lg pl-5 border-none shadow placeholder:text-base placeholder-slate-500'
                        placeholder='Describe a feature you want to add'
                        value={featureTitle}
                        onChange={handleFeatureTitleChange} // Attach onChange handler
                        showLabel={false}
                        required
                    />
                    {showTextArea && (
                        <div className='bg-white flex flex-col items-end shadow rounded-lg p-3'>
                            <TextArea
                                showCharCount={false}
                                isUnlimited={true}
                                className='border-none'
                                placeholder='Paste your product requirements text here...'
                                value={textareaValue}
                                onChange={handleTextareaChange}/>
                            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
                            <Button
                                text="Add the Feature (10 Credits)"
                                onClick={handleButtonClick}
                                disabled={textareaValue === "" || documentTitle === ""}
                            />
                        </div>
                    )}


                </div>
            </div>
        </SidebarLayout>
    );
}

