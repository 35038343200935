export function subTaskIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4311_5089)">
                <rect width="24" height="24" rx="6" fill="#4BAEE8"/>
                <path d="M6.5 6H14.5C14.6326 6 14.7598 6.05268 14.8536 6.14645C14.9473 6.24021 15 6.36739 15 6.5V9H17.5C17.6326 9 17.7598 9.05268 17.8536 9.14645C17.9473 9.24021 18 9.36739 18 9.5V17.5C18 17.6326 17.9473 17.7598 17.8536 17.8536C17.7598 17.9473 17.6326 18 17.5 18H9.5C9.36739 18 9.24021 17.9473 9.14645 17.8536C9.05268 17.7598 9 17.6326 9 17.5V15H6.5C6.36739 15 6.24021 14.9473 6.14645 14.8536C6.05268 14.7598 6 14.6326 6 14.5V6.5C6 6.36739 6.05268 6.24021 6.14645 6.14645C6.24021 6.05268 6.36739 6 6.5 6ZM7 14H9V9.5C9 9.36739 9.05268 9.24021 9.14645 9.14645C9.24021 9.05268 9.36739 9 9.5 9H14V7H7V14Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_4311_5089">
                    <rect width="24" height="24" rx="6" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
