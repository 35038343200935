export function ProfileIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <circle cx="16" cy="16" r="16" fill="#1E293B"/>
                <path d="M16 8C16.7911 8 17.5645 8.2346 18.2223 8.67412C18.8801 9.11365 19.3928 9.73836 19.6955 10.4693C19.9983 11.2002 20.0775 12.0044 19.9231 12.7804C19.7688 13.5563 19.3878 14.269 18.8284 14.8284C18.269 15.3878 17.5563 15.7688 16.7804 15.9231C16.0044 16.0775 15.2002 15.9983 14.4693 15.6955C13.7384 15.3928 13.1136 14.8801 12.6741 14.2223C12.2346 13.5645 12 12.7911 12 12C12 10.9391 12.4214 9.92172 13.1716 9.17157C13.9217 8.42143 14.9391 8 16 8Z" stroke="white" strokeWidth="2"/>
                <path d="M23 21.7154C22.6706 20.1019 21.794 18.6518 20.5184 17.6103C19.2429 16.5689 17.6467 16 16 16C14.3533 16 12.7571 16.5689 11.4816 17.6103C10.206 18.6518 9.32938 20.1019 9 21.7154" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3819_12775">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
