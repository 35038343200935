import React from "react";
import {PaymentSuccessIcon} from "../../../components/icons/payment/PaymentSuccess";
import Button from "../../../components/base/Button";
import {Link} from 'react-router-dom';

export const PaymentSuccess = () => {
    return (
        <div className="w-full h-full bg-white justify-center items-center flex flex-col">
            <div className="bg-white rounded-lg py-10 px-4 flex flex-col font-gilroy max-w-lg">
                <div className="flex flex-col justify-center items-center gap-4">
                    <PaymentSuccessIcon/>
                    <h3 className='font-bold text-2xl text-slate-800'>Checkout Complete!</h3>
                    <p className='text-base text-slate-800 text-center'>Congratulations! Your checkout is complete.
                        You'll receive an email confirmation shortly. Thank you for choosing Signlz.</p>
                    <Link to="/dashboard">
                        <Button text="Go home"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default PaymentSuccess;
