import React, { useState, useEffect } from 'react';

interface TextAreaProps {
    value?: string;
    onChange?:  ((value: string) => (void | undefined)) | undefined;
    className?: string;
    showCharCount?: boolean;
    isUnlimited?: boolean; // New boolean prop to toggle unlimited characters
    placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
                                               value,
                                               onChange,
                                               className = '',
                                               showCharCount = true,
                                               isUnlimited = false, // Default is false, indicating a limit of 2000 characters
                                               placeholder = "Describe your product idea or feature..."
                                           }) => {
    const [charCount, setCharCount] = useState(0);
    const maxLength = 2000;

    useEffect(() => {
        if (value) {
            setCharCount(value.length);
        }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setCharCount(newValue.length);
        if (onChange) {
            onChange(newValue);
        }
    };

    const isMaxLengthReached = !isUnlimited && charCount >= maxLength;

    return (
        <div className='relative w-full'>
            <textarea
                className={`max-w-5xl w-full min-h-[300px] mt-5 bg-white rounded-lg p-3 resize-none font-gilroy placeholder-slate-500 focus:ring-0 ${className}`}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                maxLength={isUnlimited ? undefined : maxLength} // Apply maxLength conditionally
            ></textarea>
            {showCharCount && (
                <div className={`flex px-3 mt-2 text-xs font-gilroy ${isMaxLengthReached ? 'text-red-500' : 'text-slate-400'}`}>
                    {!isUnlimited && `${charCount} / ${maxLength}`} {/* Display count only when not unlimited */}
                </div>
            )}
        </div>
    );
};

export default TextArea;
