import axios from 'axios';
import { useSearchParams, useNavigate,  } from "react-router-dom";
import { useEffect, useState } from "react";
import {AuthToken} from "../../service/http/AuthToken";
import Lottie from 'lottie-react';
import lottieJson from '../../assets/signlzMaskot.json';
// @ts-ignore
import mixpanel from "mixpanel-browser";
export const GoogleAuth = () => {
    const [searchParams] = useSearchParams();
    const authToken = new AuthToken();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State for loading


    const handleGoogleAuth = async () => {
        // Convert searchParams to a query string
        setIsLoading(true);
        const queryString = Array.from(searchParams).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

        const url = `${process.env.REACT_APP_API_URL}/google/auth?${queryString}`;

        try {
            // Make an API request to the constructed URL
            const response = await axios.get(url);
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                authToken.setTokens({
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                });

                // Fetch the user data from the API
                const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
                    headers: {
                        'Authorization': `Bearer ${response.data.access_token}`,
                    },
                });
                if (userDataResponse && userDataResponse.data) {
                    const userData = userDataResponse.data;

                    // Identify the user in Mixpanel
                    mixpanel.identify(userData.id); // use a unique identifier, like user id

                    // Set Mixpanel user profile
                    mixpanel.people.set({
                        '$email': userData.email, // assuming email is part of the user data
                        '$name': userData.name, // assuming name is part of the user data
                        // Add any other user properties you wish to track
                    });

                    // Track the Google sign up event
                    mixpanel.track('Google Sign In/Up', {
                        'method': 'Google',
                        'userId': userData.id
                    });
                }

                navigate('/dashboard');

            }
            } catch (error) {
            console.error("Error during Google authentication:", error);
            // Handle errors as needed
        }
    };

    useEffect(() => {
        handleGoogleAuth().catch();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Lottie
                    loop
                    autoplay
                    animationData={lottieJson}
                    height={300}
                    width={300}
                />
            </div>
        );
    }

    return null;
};
