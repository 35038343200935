import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {AppRoutes} from "./routes";
import EmptyLayout from "../components/common/layouts/EmptyLayout";
import {PrivateLayout} from "../components/layouts";
import {
    Home,
    GeneratePRD,
    GenerateTasks,
    MyPRDs,
    Pricing,
    ProTips,
    SinglePRD,
    SignUp,
    SignIn,
    ForgotPassword,
    ResetPassword,
    VerifyYourEmail,
    VerifyEmail, GenerateTechSpec, AddFeature,
}
    from "../pages";
import {initAxiosInterceptors} from "../service/http/interceptors";
import PaymentSuccess from "../pages/Pricing/Payments/PaymentSuccess";
import PaymentCanceled from "../pages/Pricing/Payments/PaymentCanceled";
import PaymentFailed from "../pages/Pricing/Payments/PaymentFailed";
import {EditPRD} from "../pages/SinglePRD/EditPRD";
import {SingleTS} from "../pages/SinglePRD/SingleTS";
import {EditTS} from "../pages/SinglePRD/EditTS";
import {Tasks} from "../pages/SinglePRD/Tasks";
import {GoogleAuth} from "../pages/authentication/GoogleAuth";
import {ImprovePRD} from "../pages/Dashboard/ImprovePRD";



function RouterWrapper() {
    initAxiosInterceptors();
    return (
        <BrowserRouter>
            <Routes>
                {/* Redirect default route */}
                <Route path="/" element={<Navigate to={AppRoutes.HOME_INDEX}/>}/>

                <Route>
                    {/* Dashboard */}
                    <Route path={AppRoutes.HOME_INDEX} element={<PrivateLayout><Home/></PrivateLayout>}/>
                    <Route path={AppRoutes.GENERATE_PRD_INDEX} element={<PrivateLayout><GeneratePRD/></PrivateLayout>}/>
                    <Route path={AppRoutes.IMPROVE_PRD_INDEX} element={<PrivateLayout><ImprovePRD/></PrivateLayout>}/>
                    <Route path={AppRoutes.ADD_FEATURE_INDEX} element={<PrivateLayout><AddFeature/></PrivateLayout>}/>
                    <Route path={AppRoutes.GENERATE_TECH_SPEC_INDEX} element={<PrivateLayout><GenerateTechSpec/></PrivateLayout>}/>
                    <Route path={AppRoutes.GENERATE_TASKS_INDEX} element={<PrivateLayout><GenerateTasks/></PrivateLayout>}/>

                    {/* My PRDs */}
                    <Route path={AppRoutes.MY_PRDS_INDEX} element={<PrivateLayout><MyPRDs/></PrivateLayout>}/>
                    <Route path={AppRoutes.SINGLE_PRD_INDEX} element={<PrivateLayout><SinglePRD/></PrivateLayout>}/>
                    <Route path={AppRoutes.EDIT_PRD_INDEX} element={<PrivateLayout><EditPRD/></PrivateLayout>}/>
                    <Route path={AppRoutes.SINGLE_TS_INDEX} element={<PrivateLayout><SingleTS/></PrivateLayout>}/>
                    <Route path={AppRoutes.EDIT_TS_INDEX} element={<PrivateLayout><EditTS/></PrivateLayout>}/>
                    <Route path={AppRoutes.TASKS_INDEX} element={<PrivateLayout><Tasks/></PrivateLayout>}/>

                    {/* Pro Tips */}
                    <Route path={AppRoutes.PRO_TIPS_INDEX} element={<PrivateLayout><ProTips/></PrivateLayout>}/>

                    {/* Pricing */}
                    <Route path={AppRoutes.PRICING_INDEX} element={<PrivateLayout><Pricing/></PrivateLayout>}/>

                    {/* Payment */}
                    <Route path={AppRoutes.PAYMENT_SUCCESS} element={<PrivateLayout><PaymentSuccess/></PrivateLayout>}/>
                    <Route path={AppRoutes.PAYMENT_CANCELED} element={<PrivateLayout><PaymentCanceled/></PrivateLayout>}/>
                    <Route path={AppRoutes.PAYMENT_FAILED} element={<PrivateLayout><PaymentFailed/></PrivateLayout>}/>

                </Route>
                <Route element={<EmptyLayout/>}>
                    {/* Authentication / Authorization */}
                    <Route path={AppRoutes.AUTH_LOGIN} element={<SignIn/>}/>
                    <Route path={AppRoutes.AUTH_SIGNUP} element={<SignUp/>}/>
                    <Route path={AppRoutes.AUTH_FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                    <Route path={AppRoutes.AUTH_RESET_PASSWORD} element={<ResetPassword/>}/>
                    <Route path={AppRoutes.AUTH_VERIFY_EMAIL} element={<VerifyEmail/>}/>
                    <Route path={AppRoutes.AUTH_VERIFY_YOUR_EMAIL} element={<VerifyYourEmail/>}/>
                    <Route path={AppRoutes.AUTH_GOOGLE} element={<GoogleAuth/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default RouterWrapper;
