import React, {Suspense,useEffect} from "react";
import RouterWrapper from "./router/RouterWrapper";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./index.css";
import mixpanel from '../src/service/http/mixpanelInstance';
const queryClient = new QueryClient();

function App() {
    useEffect(() => {
        const sessionStart = new Date();
        localStorage.setItem('sessionStart', sessionStart.toISOString());
        mixpanel.track('Session Start');
        mixpanel.people.set({
            'Last Session Start': sessionStart.toISOString(),
        });

        // Cleanup function to track session end
        const handleSessionEnd = () => {
            const storedStart = localStorage.getItem('sessionStart');
            if (storedStart) {
                const sessionStart = new Date(storedStart);
                const sessionEnd = new Date();
                const sessionDuration = (sessionEnd.getTime() - sessionStart.getTime()) / 1000; // Duration in seconds

                mixpanel.track('Session End', {
                    'Duration': sessionDuration,
                });
                mixpanel.people.set({
                    'Last Session End': sessionEnd.toISOString(),
                    'Last Session Duration': sessionDuration,
                });

                localStorage.removeItem('sessionStart');
            }
        };

        // Event listener for page unload
        window.addEventListener('beforeunload', handleSessionEnd);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleSessionEnd);
            handleSessionEnd(); // Also call it in case of SPA navigation
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={"Loading ;)"}>
                <RouterWrapper/>
            </Suspense>
        </QueryClientProvider>
    );
}

export default App;
