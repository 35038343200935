import React, {useEffect, useState} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import ReactMarkdown from 'react-markdown';
import {ArrowLeftIcon, Square2StackIcon, PencilSquareIcon, ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {Document, Packer, Paragraph, HeadingLevel} from "docx";
import Button from "../../components/base/Button";
import gfm from 'remark-gfm';
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import {CloseIcon} from "../../components/icons/close";
import Banner from "../../components/base/Banner";
// @ts-ignore
import mixpanel from "mixpanel-browser";

export function SinglePRD() {
    const {id} = useParams<{ id: string }>();
    const [prdDetails, setPrdDetails] = useState<any>(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const authToken = new AuthToken();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [prdStatus, setPrdStatus] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const navigateToEdit = () => {
        if (prdStatus === 'generating_tech_spec' || prdStatus === 'generating_tasks') {
            setShowPopup(true);
        } else {
            navigate(`/edit-prd/${id}`);
        }
    };

    const markdownToDocx = (markdownText: string) => {
        const lines = markdownText.split("\n");
        const children: any[] = [];

        lines.forEach((line) => {
            if (line.startsWith("##### ")) {
                children.push(new Paragraph({text: line.slice(6), heading: HeadingLevel.HEADING_5}));
            } else if (line.startsWith("#### ")) {
                children.push(new Paragraph({text: line.slice(5), heading: HeadingLevel.HEADING_4}));
            } else if (line.startsWith("### ")) {
                children.push(new Paragraph({text: line.slice(4), heading: HeadingLevel.HEADING_3}));
            } else if (line.startsWith("## ")) {
                children.push(new Paragraph({text: line.slice(3), heading: HeadingLevel.HEADING_2}));
            } else if (line.startsWith("# ")) {
                children.push(new Paragraph({text: line.slice(2), heading: HeadingLevel.HEADING_1}));
            } else {
                children.push(new Paragraph(line));
            }
        });

        return children;
    };

    const getPrdText = () => {
        if (prdDetails?.prd?.prd_text && !prdDetails?.prd?.versions) {
            return prdDetails?.prd?.prd_text;
        } else if (prdDetails?.prd?.versions && prdDetails.prd.versions.length > 0) {
            const lastIndex = prdDetails.prd.versions.length - 1;
            return prdDetails.prd.versions[lastIndex].prd_text;
        } else {
            return prdDetails?.prd?.generated_prd || "Loading...";
        }
    };
    // New function to get combined prd_text and added_feature
    const getCombinedPrdText = () => {
        const prdText = getPrdText();
        const addedFeature = prdDetails?.prd?.added_feature || "";
        return `${prdText}\n\n${addedFeature}`; // Combine with a newline in between
    };

    const getAddedFeature = () => {
        return prdDetails?.prd?.added_feature || "";
    };

    const handleDownloadDocx = async () => {
        const latestTechSpecText = getCombinedPrdText();
        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "defaultParagraph",
                        name: "Default Paragraph",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 14,
                            font: "Arial",
                        },
                    },
                ],
            },
            sections: [
                {
                    properties: {},
                    children: markdownToDocx(latestTechSpecText),
                },
            ],
        });

        // Generate docx file and trigger download
        const blob = await Packer.toBlob(doc);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Use the PRD title as the filename, if available
        link.download = prdDetails?.prd?.title ? `${prdDetails?.prd?.title}.docx` : 'generated_prd.docx';

        link.click();
        URL.revokeObjectURL(url);

        // Track the download event with Mixpanel
        mixpanel.track('PRD Download', {
            'PRD ID': id,
        });
    };
    useEffect(() => {
        mixpanel.track("PRD Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);

    const handleCopyClick = async () => {
        try {
            const latestTechSpecText = getCombinedPrdText();
            const title = prdDetails?.prd?.title;
            const fullTextToCopy = title ? `${title}\n\n${latestTechSpecText}` : latestTechSpecText;

            await navigator.clipboard.writeText(fullTextToCopy);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 3000);  // Hide the tooltip after 3 seconds
            // Track the copy event with Mixpanel
            mixpanel.track('PRD Copy', {
                'PRD ID': id,
            });

        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    useEffect(() => {
        const fetchPrdDetails = async () => {
            setIsLoading(true); // Set loading state to true before API call
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    setPrdDetails(response.data);
                    setPrdStatus(response.data.prd.status);
                }
            } catch (error) {
                console.error("Error fetching PRD details:", error);
            } finally {
                setIsLoading(false); // Set loading state to false after API call is complete
            }
        };

        fetchPrdDetails().catch();
    }, [id, authToken.getAccessToken()]);

    let prdTextToRender = getPrdText();
    let addedFeatureToRender = getAddedFeature();

    const handleGenerateTS = async () => {
        const latestPrdText = getCombinedPrdText();

        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${id}/tech_spec/generate`;

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    params: {
                        prd_text: latestPrdText,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate(`/tech-spec/${id}`);
                mixpanel.track("Generate TS Start", {
                    "PRD ID": id,
                });
            } else {
                console.error('API did not return success.');
            }
        } catch (error) {
            console.error('Error in API call:', error);
            setShowBanner(true);
        }
    };


    const handleButtonClick = () => {
        if (prdStatus === 'tech_spec_completed' || prdStatus === 'generating_tasks' || prdStatus === 'tasks_completed') {
            navigate(`/tech-spec/${id}`);
        } else if (prdStatus === 'tech_spec_generating') {
            // Do nothing or show a message if you want
        } else {
            handleGenerateTS().catch();
        }
    };

    const getButtonText = () => {
        if (prdStatus === 'tech_spec_completed' || prdStatus === 'tasks_completed' || prdStatus === 'generating_tasks') {
            return "View TS";
        } else if (prdStatus === 'tech_spec_generating') {
            return "TS Processing";
        } else {
            return "Generate TS (10 Credits)";
        }
    };
    const onClose = () => {
        setShowPopup(false);
    };

    const handleDuplicatePRD = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${id}/duplicate`,
                {},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate('/my-prds');
            } else {
                console.error('API did not return success.');
            }
        } catch (error) {
            console.error('Error in API call:', error);
        }
    };

    return (
        <SidebarLayout>
            <FeedbackButton/>
            {showBanner && <Banner/>}
            <div className='mx-auto max-w-5xl max-sm:px-4 h-20 z-10 bg-slate-100'>
                <div className='fixed py-5 bg-slate-100 z-10'>
                    <div
                        className='w-[1028px] max-md:w-full max-md:gap-4 max-md:px-4 flex flex-row justify-between items-center'>
                        <div className='flex flex-row gap-2 items-center'>
                            <Link to='/my-prds' className='flex flex-row gap-2 items-center'>
                                <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                                <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to PRDs</h6>
                            </Link>
                        </div>
                        <div className='flex flex-row gap-2 items-center'>
                            <Button text={getButtonText()} onClick={handleButtonClick}/>
                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={handleDownloadDocx}
                                 title='Download Docx'
                            >
                                <ArrowDownTrayIcon
                                    className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800"/>
                            </div>
                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={navigateToEdit}
                                 title='Edit PRD'

                            >
                                <PencilSquareIcon
                                    className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800"/>
                            </div>
                            {
                                showPopup && (
                                    <div
                                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
                                        onClick={onClose}>
                                        <div
                                            className="bg-white rounded-lg py-10 px-4 flex flex-col font-gilroy max-w-lg gap-6"
                                            onClick={(e) => e.stopPropagation()} // Prevents the popup from closing when clicking inside
                                        >
                                            <div className='flex flex-row justify-between w-full'>
                                                <h4 className='font-bold text-xl text-slate-800'>Editing Disabled</h4>
                                                <div
                                                    className='w-6 h-6 hover:bg-slate-200 hover:rounded-full cursor-pointer'
                                                    onClick={onClose}><CloseIcon/></div>
                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <p>Sorry, you can't edit this PRD right now because a technical
                                                    specification is being generated at this moment. However, you can
                                                    duplicate the PRD to create a new version and make your edits there.</p>
                                                <Button text="Duplicate PRD" onClick={handleDuplicatePRD}/>

                                            </div>

                                        </div>
                                    </div>
                                )
                            }

                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={handleCopyClick}
                                 title='Copy PRD Text'
                            >
                                <Square2StackIcon
                                    className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800"/>
                                {copySuccess && (
                                    <div
                                        className="absolute top-1 text-sm font-gilroy text-white bg-black px-3 py-1 rounded-lg shadow">
                                        Copied!
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mx-auto max-w-5xl mt-2 mb-16 py-5 max-sm:px-4'>
                <div className='flex flex-col gap-6 mb-10 font-gilroy'>
                    {prdDetails ? (
                        <>
                            {/* PRD Text Card */}
                            <div className="bg-white px-5 py-5 rounded-lg shadow">
                                <div className="relative customMarkdown">
                                    <ReactMarkdown remarkPlugins={[gfm]}>
                                        {prdTextToRender}
                                    </ReactMarkdown>
                                </div>
                            </div>

                            {/* Added Feature Card - Conditional Rendering */}
                            {addedFeatureToRender && (
                                <div className="bg-white px-5 py-5 rounded-lg shadow">
                                    <h2 className="text-2xl font-bold text-slate-800">Added Features</h2>
                                    <div className="relative customMarkdown">
                                        <ReactMarkdown remarkPlugins={[gfm]}>
                                            {addedFeatureToRender}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        "Loading..."
                    )}
                </div>
            </div>
        </SidebarLayout>
    );
}
