import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import React, {useState, useEffect, useRef} from "react";
import TextArea from "../../components/base/TextArea";
import Lottie from 'lottie-react';
import lottieJson from '../../assets/signlzMaskot.json';
import Button from "../../components/base/Button";
import {useNavigate, useLocation, Link} from 'react-router-dom';
import axios from 'axios';
import Banner from "../../components/base/Banner";
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import lottiePRDAnimation from "../../assets/doc_loader.json";
// @ts-ignore
import mixpanel from "mixpanel-browser";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";

interface QuestionAnswer {
    question: string;
    answer: string;
}

export function GeneratePRD() {
    const [textareaValue, setTextareaValue] = useState("");
    const [buttonText, setButtonText] = useState("Process (10 Credits)");
    const [divText, setDivText] = useState("Let’s get started with your killer idea. Please describe Your product or feature.");
    const [showTextArea, setShowTextArea] = useState(true);
    const [prdId, setPrdId] = useState<string | null>(null);
    const [questions, setQuestions] = useState<QuestionAnswer[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [typewriterIndex, setTypewriterIndex] = useState(0);
    const [prdStatus, setPrdStatus] = useState("");  // New state variable to track PRD status
    const navigate = useNavigate();  // Initialize useHistory
    const [initialLoading, setInitialLoading] = useState(false);
    const [stopAnimation, setStopAnimation] = useState(false);
    const [presetText, setPresetText] = useState<string | null>(null);
    const [showPresetDivs, setShowPresetDivs] = useState(true);
    const [showBanner, setShowBanner] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const location = useLocation();
    const initialText = (location.state as any)?.initialText;
    const draftPrdId = new URLSearchParams(location.search).get('prdId');
    const authToken = new AuthToken();
    const [retryCount, setRetryCount] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);

    const maxRetries = 3; // Set a maximum number of retries
    const loadingSentences = [
        "Got it... give me a moment to digest it.",
        "Initializing Process... Setting Things up for you.",
        "Rolling up my digital sleeves...",
        "Gathering required information... Making sure I have what I need.",
        "Your boyfriend Is cheating on you! Oh no. This message was for my sister.",
        "Running quality checks... Checking Everything Twice for Accuracy.",
        "Doing some heavy lifting... But I've Been to the gym, so it's all under control.",
        "Assembling components... We're halfway there!",
        "Planting the seeds of success... Watering with care.",
        "Almost there! Preparing some questions we need to clarify and review before we proceed.",
        "We're on the final stretch! Remember, Signlz thrives when you share the details that matter most. Your input powers our precision!",
        "Don't refresh the page, we are almost there... Sometimes it takes more time to process your request.   "
    ];
    const [showLoadingText, setShowLoadingText] = useState(false);
    const handleCheckboxChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
    };


    const stopAnimationRef = useRef(false);
    const [showEssenceCapturedMessage, setShowEssenceCapturedMessage] = useState(false);
    const typeSentence = async (sentence: string, isLastSentence: boolean = false, delay: number = 75, onStop?: () => void) => {
        for (let i = 0; i < sentence.length; i++) {
            if (stopAnimationRef.current) {
                onStop?.(); // Call the onStop callback if provided
                return; // If stopAnimation is true, exit the function
            }
            setDivText(sentence.substring(0, i + 1));

            await new Promise<void>(resolve => {
                const timeoutId = setTimeout(() => {
                    resolve();
                    clearTimeout(timeoutId);
                }, delay);
            });
        }
        if (!isLastSentence) {
            // Wait for a second before clearing the text, unless stopAnimation is true
            await new Promise<void>(resolve => {
                const timeoutId = setTimeout(() => {
                    if (!stopAnimation) {
                        setDivText("");
                    } else {
                        onStop?.(); // Call the onStop callback if provided
                    }
                    resolve();
                    clearTimeout(timeoutId); // Clear the timeout after it completes
                }, 1000);
                if (stopAnimationRef.current) {
                    clearTimeout(timeoutId);
                }
            });
        }
    };

    // Update the stopTyping function
    const stopTyping = (message: string = "") => {
        if (!stopAnimationRef.current) {
            stopAnimationRef.current = true;
            if (message) {
                setDivText(message);
                setShowEssenceCapturedMessage(true); // Set the flag to true when the message is shown
            }
        }
    };

    useEffect(() => {
        if (prdStatus === "initial_request_completed" && !showEssenceCapturedMessage) {
            stopTyping();
            setShowPresetDivs(false);
        }
    }, [prdStatus, showEssenceCapturedMessage]);

    const playLoadingSentences = async () => {
        for (let i = 0; i < loadingSentences.length; i++) {
            const isLastSentence = i === loadingSentences.length - 1;
            await typeSentence(loadingSentences[i], isLastSentence, 75, () => {
                // This callback gets called if the animation is stopped
                setDivText(""); // Clear the text immediately
            });
            if (stopAnimation) {
                break; // Exit the loop if animation is stopped
            }
        }
    };

    const handlePresetClick = (text: string) => {
        setPresetText(text);// Hide the preset divs
        setShowTextArea(false);
    };
    const handleTextareaChange = (value: string) => {
        setTextareaValue(value);
    };

    // Function to make initial request and handle retries
    const makeInitialRequest = async (requestText: string | null) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/initial_request`,
                null,
                {
                    params: {initial_request: requestText},
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                setStopAnimation(false);
                setShowLoadingText(true);
                playLoadingSentences().catch();
                const data = response.data;
                setPrdId(data.prd_id);
                setShowPresetDivs(false);
                setShowTextArea(false);
                setRetryCount(0); // Reset retry count on success
                checkPrdStatus(data.prd_id).catch();
            } else {
                console.error('API did not return success.');
                setErrorMsg(response.data.message || 'Something went wrong. Please try again.');
                setIsLoading(false);
            }
        } catch (error) {

            console.error('Error while making initial request:', error);
            setIsLoading(false);
            setShowBanner(true);
        }
    };

    const checkPrdStatus = async (prdId: string) => {
        let intervalId = setInterval(async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${prdId}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });

                const data = response.data;

                if (data.prd.status === "initial_request_completed") {

                    // Mixpanel Tracking
                    // Use the user's unique identifier here, such as an email or user ID
                    mixpanel.identify(data.prd.user_id); // Ensure you have the user ID available
                    mixpanel.track('Initial Request Success', {
                        '$prdID': data.prd.id,
                        '$status': data.prd.status,
                        '$initialRequest': data.prd.initial_request,
                        '$userID': data.prd.user_id,
                        '$prdTitle': data.prd.title,
                    });
                    stopTyping(" ");
                    setShowLoadingText(false);
                    clearInterval(intervalId);
                    setPrdStatus(data.prd.status); // Update the PRD status
                    setQuestions(data.prd.questions);
                    setCheckedState(new Array(data.prd.questions.length).fill(true));
                    setShowTextArea(false);
                    setButtonText("Generate PRD (10 Credits)");
                    setShowPresetDivs(false);
                    setInitialLoading(false);
                } else if (data.prd.status === "initial_request_failed") {
                    if (retryCount < maxRetries) {
                        clearInterval(intervalId);
                        setRetryCount(retryCount + 1); // Increment retry count
                        makeInitialRequest(textareaValue || presetText); // Retry with the same request text
                    } else {
                        clearInterval(intervalId);
                        // Handle max retries reached, perhaps by showing an error message
                        setErrorMsg('Max retries reached. Please try again later.');
                    }
                }
            } catch (error) {
                console.error("Error while checking PRD status:", error);

                // Check if error is an Axios error
                if (axios.isAxiosError(error) && error.response) {
                    // If status code is 404, 401, or any error status, stop making requests
                    if ([404, 401].includes(error.response.status) || error.response.status >= 400) {
                        clearInterval(intervalId);
                    }
                }
            }
        }, 5000);
    };

    useEffect(() => {
        if (initialText) {
            setTextareaValue(initialText);
        }
    }, [initialText]);

    useEffect(() => {
        const fromCard = localStorage.getItem('fromCard');
        if (fromCard === 'true') {
            setInitialLoading(true);
        }
        localStorage.removeItem('fromCard');
        if (draftPrdId) {
            setPrdId(draftPrdId);
            checkPrdStatus(draftPrdId)
                .catch()
        }
    }, [draftPrdId]);

    const handleGeneratePrd = async () => {
        if (!prdId) {
            console.error("prdId is not available");
            return;
        }
        // Filter questions based on the checked state
        const filteredQuestions = questions.filter((_, index) => checkedState[index]);
        const questionsAndAnswersString = filteredQuestions
            .map(qa => `Question: ${qa.question}, Answer: ${qa.answer}`)
            .join('\n');

        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${prdId}/generate`;

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    params: {questions_and_answers: questionsAndAnswersString},
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate("/my-prds");  // Navigate to /my-prds page
                mixpanel.track("Generate PRD Start", {
                    "PRD ID": prdId,
                });
            } else {
                console.error('API did not return success.');
            }
        } catch (error) {
            console.error('Error in API call:', error);
        }
    };

    const handleAnswerChange = (index: number, newAnswer: string) => {
        // Clone the questions array
        const updatedQuestions = [...questions];

        // Update the answer for the specific question
        updatedQuestions[index].answer = newAnswer;

        // Update the state
        setQuestions(updatedQuestions);
    };


    const handleButtonClick = async () => {
        const initialRequestText = textareaValue || presetText;
        if (!initialRequestText) {
            console.error('No input provided.');
            return;
        }

        setStopAnimation(false);
        setIsLoading(true);
        setShowBanner(false);  // Hide the banner initially

        if (buttonText === "Process (10 Credits)" && !prdId) { // Only proceed if there's no prdId
            await makeInitialRequest(initialRequestText);
        } else if (buttonText === "Generate PRD (10 Credits)" && prdId) {
            await handleGeneratePrd();
        }

        setIsLoading(false);
    };


    useEffect(() => {
        if (typewriterIndex < loadingSentences.length && isLoading) {
            const isLastSentence = typewriterIndex === loadingSentences.length - 1;
            typeSentence(loadingSentences[typewriterIndex], isLastSentence).then(() => {
                if (!isLastSentence) {
                    setTypewriterIndex(typewriterIndex + 1);
                }
            });
        }
    }, [typewriterIndex]);

    useEffect(() => {
        if (presetText && !prdId) { // Only call handleButtonClick if there's no prdId set
            handleButtonClick().catch();
        }
    }, [presetText, prdId]); // Add prdId as a dependency
    useEffect(() => {
        mixpanel.track("Generate PRD Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);


    if (initialLoading) {
        return <SidebarLayout>
            <div className='mx-auto max-w-xs mt-16 font-gilroy max-sm:px-4 text-lg font-bold'>
                <Lottie animationData={lottiePRDAnimation}/>
            </div>
        </SidebarLayout>;
    }


    return (
        <SidebarLayout>
            <FeedbackButton/>
            {showBanner && <Banner/>}
            <div className='mx-auto max-w-5xl mt-8 max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10'>
                    <div className='flex flex-row gap-2 items-center'>
                        <Link to='/dashboard' className='flex flex-row gap-2 items-center'>
                            <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                            <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to AI
                                Dashboard</h6>
                        </Link>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Generate PRD</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Describe your product idea or
                                feature</p>
                        </div>
                        <div
                            className='flex flex-row gap-3 max-w-5xl h-16 rounded-lg bg-slate-900 items-center px-3 py-2'>
                            <Lottie animationData={lottieJson} className='w-10 h-10'/>
                            <p className='font-gilroy font-light text-white text-base max-sm:text-sm'>{divText}</p>
                            {showEssenceCapturedMessage && (
                                <p className='font-gilroy font-light text-white text-base max-sm:text-sm'>
                                    I've captured the essence of your idea. Please review the details and suggest any
                                    adjustments or insights so I can better understand your vision.
                                </p>
                            )}
                        </div>
                        {showLoadingText && (
                            <p className='font-gilroy font-normal text-lg text-slate-600 max-sm:text-sm'>
                                Based on your initial request, I'm preparing a set of questions to ensure that I create
                                comprehensive and high-quality Product Requirements.
                            </p>
                        )}

                        {showPresetDivs && (
                            <div className='flex flex-row gap-4 max-sm:flex-col'>
                                <div id="idea"
                                     className='w-1/2 max-sm:w-full text-slate-500 px-5 py-3 bg-slate-50 rounded-lg flex border-2 border-white cursor-pointer hover:bg-white hover:shadow hover:text-slate-900'
                                     onClick={() => handlePresetClick("An app for musicians. They need to rent equipment, and pin their location on the map.")}>
                                    <p className='font-gilroy text-sm '>e.g. “An app for musicians. They need to rent
                                        equipment, and pin their location on the map.”</p>
                                </div>
                                <div id="feature"
                                     className='w-1/2 max-sm:w-full text-slate-500 px-5 py-3 bg-slate-50 rounded-lg flex border-2 border-white cursor-pointer hover:bg-white hover:shadow hover:text-slate-900'
                                     onClick={() => handlePresetClick("A basic sign up/sign in functional via Email, Google and Apple.")}>
                                    <p className='font-gilroy text-sm'>e.g. “A basic sign up/sign in functional via
                                        Email, Google and Apple.”</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {showTextArea ? (
                        <div className='bg-white flex flex-col items-end shadow rounded-lg p-3'>
                            <TextArea showCharCount={true} className='border-none' value={textareaValue}
                                      onChange={handleTextareaChange}/>
                            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
                            <Button text={buttonText} onClick={handleButtonClick} disabled={textareaValue === ""}/>
                        </div>
                    ) : isLoading ? (
                        <div className='bg-white font-gilroy mt-4 p-4 h-full rounded-lg shadow'>
                            Loading...
                        </div>
                    ) : (
                        questions.map((q, index) => (
                            <div key={index} className='bg-white font-gilroy p-4 h-full rounded-lg shadow'>
                                <div className='flex flex-row gap-3 items-center mb-2'>
                                    <input
                                        type="checkbox"
                                        checked={checkedState[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                        className="form-checkbox h-5 w-5 text-blue rounded cursor-pointer"
                                    />
                                    <h2 className="text-xl  font-gilroy font-bold">{q.question}</h2>
                                </div>
                                <TextArea
                                    showCharCount={true}
                                    className="bg-slate-50 rounded-lg border-1 border-slate-200"
                                    value={q.answer}
                                    onChange={(newAnswer) => handleAnswerChange(index, newAnswer)} // Updated here
                                />
                            </div>
                        ))

                    )}
                    {prdStatus === "initial_request_completed" && (
                        <Button className='w-44 self-end' text="Generate PRD (10 Credits)" onClick={handleGeneratePrd}/>
                    )}

                </div>
            </div>
        </SidebarLayout>
    );
}
