import React, {useEffect, useState} from "react";
import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import Button from "../../components/base/Button";
import Card from '../../components/base/Card';
import {Link} from 'react-router-dom';
import axios from 'axios';
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
// @ts-ignore
import mixpanel from "mixpanel-browser";


type PRD = {
    id: string;
    created_at: string;
    title: string;
    initial_request: string;
    status: 'initial_request_completed' | 'generating_prd' | 'improving_prd' | 'completed' | 'failed';
};


function formatDate(inputDate: string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(inputDate);
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate() as number;
    const year = date.getUTCFullYear() as number;

    let hours = date.getUTCHours() as number;
    let minutes = date.getUTCMinutes() as number;
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? Number('0' + minutes) : minutes;
    const strTime = `${hours}:${minutes} ${ampm} UTC`;

    return `${month} ${day}, ${year} - ${strTime}`;
}


export function MyPRDs() {
    const [prds, setPrds] = useState<PRD[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [prdToDelete, setPrdToDelete] = useState<string | null>(null);
    const authToken = new AuthToken();
    const handleViewPRD = () => {
    };
    useEffect(() => {
        // Fetch PRDs from the API
        const fetchPrds = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prds`, {
                    headers: {
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });

                if (response.status === 200) {
                    setPrds(response.data.prds);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching PRDs:", error);
                setLoading(false);
            }
        };
        fetchPrds().catch();

    }, [authToken.getAccessToken()]);


// useEffect for polling
    useEffect(() => {
        const terminalStatuses = ['completed', 'generating_tasks', 'duplicated', 'tasks_completed', 'initial_request_completed', 'failed', 'processing_initial_request'];
        const pollPrds = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prds`, {
                    headers: {
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });

                if (response.status === 200) {
                    const updatedPrds = response.data.prds;
                    // Update only if status changes
                    setPrds(prevPrds => {
                        return prevPrds.map(prd => {
                            const updatedPrd = updatedPrds.find((up: PRD) => up.id === prd.id);
                            // If the PRD status has changed to 'failed', send the failure event to Mixpanel
                            if (updatedPrd && updatedPrd.status === 'failed' && prd.status !== 'failed') {
                                mixpanel.track("PRD Generation Failed", {
                                    "PRD ID": updatedPrd.id,
                                    // You can add more properties as needed
                                });
                            }
                            // Check if the PRD status has changed to 'completed'
                            if (updatedPrd && updatedPrd.status === 'completed' && prd.status !== 'completed') {
                                // Send the success event to Mixpanel
                                mixpanel.track("PRD Generation Success", {
                                    "PRD ID": updatedPrd.id,
                                    // You can add more properties as needed
                                });
                            }

                            return updatedPrd ? updatedPrd : prd;
                        });
                    });
                }
            } catch (error) {
                console.error("Error polling PRDs:", error);
            }
        };

        const intervalId = setInterval(() => {
            const incompletePrds = prds.filter(prd => !terminalStatuses.includes(prd.status));
            if (incompletePrds.length > 0) {
                pollPrds().catch();
            }
        }, 10000);  // 10 seconds

        return () => {
            clearInterval(intervalId);  // Cleanup
        };
    }, [prds, authToken]);


    const handleDelete = async (prdId: string) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/prd/${prdId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken.getAccessToken()}`
                }
            });

            if (response.status === 200) {
                // Filter out the deleted PRD from the local state

                setPrds(prevPrds => prevPrds.filter(prd => prd.id !== prdId));
                mixpanel.track('Delete PRD', {
                    'PRD ID': prdId,
                    // You can add more properties to this event if you need
                });
            }
        } catch (error) {
            console.error("Error deleting PRD:", error);
        }
    };

    const handleDownload = async (prdId: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${prdId}/download/docx`, {
                responseType: 'blob', // Important for handling binary data
                headers: {
                    'Authorization': `Bearer ${authToken.getAccessToken()}`
                }
            });

            if (response.status === 200) {
                const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `PRD-${prdId}.docx`; // Set the filename for the download
                document.body.appendChild(a); // Append the link to the body
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a); // Clean up the DOM

                // Track the download event with Mixpanel
                mixpanel.track('PRD Download', {
                    'PRD ID': prdId,
                });
            }
        } catch (error) {
            console.error("Error downloading PRD:", error);
        }
    };

    const openModal = (prdId: string) => {
        setPrdToDelete(prdId);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setPrdToDelete(null);
    };

    const confirmDelete = () => {
        if (prdToDelete) handleDelete(prdToDelete).catch();
        closeModal();
    };

    useEffect(() => {
        mixpanel.track("My PRDs Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);
    return (
        <SidebarLayout>
            <div className={`mx-auto max-w-5xl mt-8 mb-16 flex flex-col gap-6 max-sm:px-4 ${prds.length === 0 ? 'mt-[25%]' : ''}`}>
                <div className={`flex flex-row justify-between items-center ${prds.length === 0 ? 'flex-col items-center' : ''}`}>
                    <h1 className={`font-gilroy font-bold text-slate-900 text-2xl ${prds.length === 0 ? 'mb-4' : ''}`}>My PRDs</h1>
                    { prds.length === 0 ? (
                        <Link to='/dashboard/generate-prd' className={`w-full ${prds.length === 0 ? 'w-[200px] flex justify-center' : ''}`}>
                            <Button text='Create New PRD' className={`w-full ${prds.length === 0 ? 'w-[200px]' : ''}`}></Button>
                        </Link>
                    ) : (
                        <Link className='!flex !justify-center' to='/dashboard/generate-prd'>
                            <Button text='Create New PRD'></Button>
                        </Link>
                    )}
                </div>
                <div className='flex flex-col gap-2'>
                    {loading ? (
                        <div className='font-gilroy text-base text-slate-600'>Loading...</div>
                    ) : (
                        prds
                            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                            .map((prd) => (
                                <Card
                                    key={prd.id}
                                    title={prd.title}
                                    date={formatDate(prd.created_at)}
                                    downloadLink={`${process.env.REACT_APP_API_URL}/prd/${prd.id}/download/docx`}
                                    onDownload={() => handleDownload(prd.id)}
                                    onDelete={() => openModal(prd.id)}
                                    status={prd.status}
                                    prdId={prd.id}
                                    onViewPRD={handleViewPRD}
                                />
                            ))
                    )}
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h3 className="font-gilroy text-xl font-bold mb-4">Are you sure you want to delete this
                            PRD?</h3>
                        <div className="flex justify-end">
                            <button
                                className="bg-slate-900 font-gilroy rounded-lg px-5 py-2 text-white text-sm hover:bg-gray-800 mr-2"
                                onClick={closeModal}>Cancel
                            </button>
                            <button className="bg-red-700 font-gilroy rounded-lg px-5 py-2 text-white text-sm hover:bg-red-500"
                                    onClick={confirmDelete}>Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <FeedbackButton/>
        </SidebarLayout>
    );
}
