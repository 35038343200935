import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {AuthToken} from "../../service/http/AuthToken";
// @ts-ignore
import mixpanel from "mixpanel-browser";
// TODO: Implement maxCredits in future iterations
// interface ProgressBarProps {
//     maxCredits?: number;
// }
// The function signature no longer includes maxCredits
const ProgressBar: React.FC = () => {
    const [credits, setCredits] = useState<number>(0);
    const authToken = useMemo(() => new AuthToken(), []);
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken.getAccessToken()}`
                }
            };
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, config);
            setCredits(response.data.balance);
            mixpanel.people.set({
                '$email': response.data.email,
                '$last_login': new Date(),
                '$name': response.data.full_name,
                '$balance': response.data.balance
            });
        } catch (error) {
            console.error("Error fetching balance:", error);
        }
    };
    useEffect(() => {
        if(authToken.getAccessToken()){
            fetchData().catch(e=>{console.log('fetch data in progress bar:', e)});
        }
    }, [authToken.getAccessToken()]);
    const formattedCredits = credits.toLocaleString();  // Format the credits with commas
    return (
        <div className='w-full'>
            <h4 className="text-slate-800 font-gilroy text-base">
                <span className='font-bold'>{formattedCredits} </span> credits left  {/* Use formattedCredits */}
            </h4>
            <div>
                <Link to='/pricing'>
                    <h6 className='font-gilroy font-semibold text-sm hover:underline text-blue'>Get More</h6>
                </Link>
            </div>
        </div>
    );
};
export default ProgressBar;
