import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import Button from "../../components/base/Button";
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
// @ts-ignore
import {Editor, EditorState, Modifier} from "draft-js";
// @ts-ignore
import {stateFromMarkdown} from 'draft-js-import-markdown';
// @ts-ignore
import {Editor as DraftEditor} from 'draft-js';
// @ts-ignore
import {stateToMarkdown} from 'draft-js-export-markdown';
import {AiSparkIcon} from "../../components/icons/aiSpark";
import {SendIcon} from "../../components/icons/send";
import {RichTextControls} from "../../components/base/RichTextEditor";
import {getCustomStyleMap} from "../../components/base/RichTextEditor";
import {CloseIcon} from "../../components/icons/close";
// @ts-ignore
import mixpanel from "mixpanel-browser";


export function EditTS() {
    const {id} = useParams<{ id: string }>();
    const [prdDetails, setPrdDetails] = useState<any>(null);
    const authToken = new AuthToken();
    const editorRef = useRef<null | DraftEditor>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [showPrompt, setShowPrompt] = useState(false);
    const [selectedText, setSelectedText] = useState("");
    const [modification_request, setPromptText] = useState("");
    const [overlayPosition, setOverlayPosition] = useState({top: 0, left: 0});
    const [showAskAIOverlay, setShowAskAIOverlay] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [changedText, setChangedText] = useState("");
    const [showPopupLoader, setShowPopupLoader] = useState(false);
    const [, setShowTechSpec] = useState(false);
    const handleTextSelection = () => {
        // Estimate the overlay height. Adjust this accordingly.
        const overlayHeight = 50;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const selectedText = currentBlock.getText().slice(start, end);

        if (selectedText.trim() !== "") {
            const selectionDOM = window.getSelection();
            if (selectionDOM && selectionDOM.rangeCount > 0) {
                const rect = selectionDOM.getRangeAt(0).getBoundingClientRect();

                const editorContainer = editorRef.current?.editor?.editorContainer as HTMLElement;
                const containerScrollTop = editorContainer ? editorContainer.scrollTop : 0;

                // Calculate the top position considering window scroll and container scroll
                const absoluteTopPosition = rect.top + window.scrollY + containerScrollTop;

                // Calculate available space above and below the selection
                const spaceAbove = absoluteTopPosition;
                const spaceBelow = window.innerHeight - (rect.bottom + window.scrollY);

                // Decide whether to position the overlay above or below the selected text
                let topPosition;
                if (spaceBelow < overlayHeight && spaceAbove > spaceBelow) {
                    // Position overlay above the selected text
                    topPosition = absoluteTopPosition - overlayHeight;
                } else {
                    // Position overlay below the selected text (existing code)
                    topPosition = absoluteTopPosition + rect.height;
                }

                // Update the overlay position
                setOverlayPosition({
                    top: topPosition,
                    left: rect.left + window.scrollX  // Adjust for any horizontal scrolling, if needed
                });
            }
            setSelectedText(selectedText);
            setShowPrompt(true);
        }

    };
    const handleSave = async () => {
        // Set loading state to true before API call
        setIsLoading(true);

        const contentState = editorState.getCurrentContent();
        const updated_tech_spec_text = stateToMarkdown(contentState);  // Convert to Markdown

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${id}/update_tech_spec_text`,
                null,  // No body for the POST request
                {
                    params: {
                        updated_tech_spec_text: updated_tech_spec_text  // The updated text goes here
                    },
                    headers: {
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            // Check for success and navigate
            if (response.status === 200) {
                navigate(`/tech-spec/${id}`);
                mixpanel.track('TS Edited', {
                    'PRD ID': id,
                });
            }
        } catch (error) {
            console.error("Error updating TS text:", error);
        }

        // Set loading state to false after API call is complete
        setIsLoading(false);
    };

    useEffect(() => {
        mixpanel.track("Edit TS Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);
    const handleSubmitPrompt = async () => {
        const originalPromptText = modification_request;
        setShowLoader(true);
        setPromptText("Processing your request, please wait...");

        let tech_spec_text = editorState.getCurrentContent().getPlainText();

        // Wrap the selectedText with <MODIFY></MODIFY> tags
        const modifiedSelectedText = `<MODIFY>${selectedText}</MODIFY>`;
        tech_spec_text = tech_spec_text.replace(selectedText, modifiedSelectedText);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${id}/modify_tech_spec_part`,
                null,
                {
                    params: {
                        tech_spec_text,
                        modification_request: originalPromptText // Use the original input text for the API call
                    },
                    headers: {
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {

                let receivedText = response.data.modified_tech_spec_part; // Assume this is where the API returns the modified text
                let cleanedText = receivedText.replace(/<\/?MODIFY>/g, ""); // Remove <MODIFY> and </MODIFY>
                setShowAskAIOverlay(false);
                setShowPrompt(false);
                setChangedText(cleanedText);
                mixpanel.track('Modify TS', {
                    'PRD ID': id,
                    'Modification Request': originalPromptText,
                    'Selected Text': modifiedSelectedText
                });
            }
            setPromptText(originalPromptText);
        } catch (error) {
            console.error("Error sending data to API:", error);

            // Optionally, reset the input field to empty or set it with an error message
            setPromptText("An error occurred. Check your credit's balance and try again.");
        } finally {
            // Hide the loader
            setShowLoader(false);
        }
    };

    useEffect(() => {
        function handleClickOutside(event: Event) {
            const overlay = document.getElementById('ask-ai-overlay');
            if (overlay && !overlay.contains(event.target as Node)) {
                setShowAskAIOverlay(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const replaceSelection = () => {
        setChangedText("");  // Close the popup

        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            changedText
        );

        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters'
        );

        setEditorState(newEditorState);
    };


    const tryAgain = () => {
        setShowPopupLoader(true); // Show loader
        handleSubmitPrompt().finally(() => setShowPopupLoader(false)); // Hide loader after request is complete
    };


    const extractSelectedText = (editorState: EditorState): string => {
        const selectionState = editorState.getSelection();
        if (!selectionState.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selectionState.getStartKey();
            const startOffset = selectionState.getStartOffset();
            const blockWithSelectionStart = contentState.getBlockForKey(startKey);
            const blockText = blockWithSelectionStart.getText();
            const selectedText = blockText.slice(startOffset, selectionState.getEndOffset());
            return selectedText;
        }
        return '';
    };


    const handleEditorChange = (newEditorState: EditorState) => {
        const newText = extractSelectedText(newEditorState);
        if (newText !== selectedText) {
            setSelectedText(newText);
            if (newText.trim() !== "") {
                const selectionDOM = window.getSelection();
                if (selectionDOM && selectionDOM.rangeCount > 0) {
                    const rect = selectionDOM.getRangeAt(0).getBoundingClientRect();
                    const parentRect = (editorRef.current?.editor?.editorContainer as HTMLElement)?.getBoundingClientRect();
                    setOverlayPosition({
                        top: rect.top - (parentRect?.top || 0) + window.scrollY,
                        left: rect.left - (parentRect?.left || 0)
                    });
                    setShowPrompt(true);
                }
            } else {
                setShowPrompt(false);
            }
        }
        setEditorState(newEditorState);
    };
    const closeChangedTextPopup = () => {
        setChangedText("");
    };


    const [editorState, setEditorState] = useState(
        EditorState.createEmpty() // Initial empty state
    );


    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (showPrompt && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showPrompt]);

    const navigateToView = () => {
        navigate(`/tech-spec/${id}`);
    }

    useEffect(() => {
        const fetchPrdDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    const techSpecVersions = response.data.prd?.tech_spec_versions;
                    let latestTechSpec = response.data.prd?.tech_spec; // Default to tech_spec

                    if (
                        techSpecVersions &&
                        Array.isArray(techSpecVersions) &&
                        techSpecVersions.length > 0
                    ) {
                        const lastIndex = techSpecVersions.length - 1;
                        latestTechSpec = techSpecVersions[lastIndex].tech_spec_text;
                    }

                    setPrdDetails(latestTechSpec);
                    setShowTechSpec(latestTechSpec);

                    // Initialize EditorState with the latest tech_spec data
                    const newEditorState = EditorState.createWithContent(
                        stateFromMarkdown(latestTechSpec || "")
                    );
                    setEditorState(newEditorState);
                }
            } catch (error) {
                console.error("Error fetching PRD details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPrdDetails();
    }, [id, authToken.getAccessToken()]);



    return (
        <SidebarLayout>
            {isLoading ? <div>Loading...</div> : null}

            {showLoader && (
                <div className="loader-popup">
                    <div className="loader"></div>
                </div>
            )}

            {changedText && (
                <div className="changed-text-popup fixed w-[720px] border-2 border-blue max-w-3xl bg-white rounded-lg shadow-sm px-3 py-3"
                     style={{top: `${overlayPosition.top}px`, zIndex: 20,left: `${overlayPosition.left}px`, marginTop:60}}>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row justify-between w-full'>
                            <h1 className='font-gilroy font-bold text-xl'>Updated text</h1>
                            <div className='w-6 h-6 hover:bg-slate-200 hover:rounded-full cursor-pointer' onClick={closeChangedTextPopup}><CloseIcon/></div>
                        </div>
                        {showPopupLoader ? (
                            <div className="loader"> Loading...</div>
                        ) : (
                            <p>{changedText}</p>
                        )}
                        <div className='flex flex-row gap-4 justify-end'>
                            <button className='font-gilroy font-semibold text-blue hover:underline' onClick={tryAgain}>Try Again</button>
                            <button className='font-gilroy font-semibold text-slate-800 hover:underline' onClick={replaceSelection}> Replace Selection</button>
                        </div>
                    </div>
                </div>
            )}

            <FeedbackButton/>
            <div className='mx-auto max-w-5xl max-sm:px-4 h-20 z-10 bg-slate-100'>
                <div className='fixed py-5 bg-slate-100 z-10'>
                    <div
                        className='w-[1028px] max-md:w-full max-md:gap-4 max-md:px-4 flex flex-row justify-between items-center'>
                        <div className='flex flex-row gap-2 items-center'>
                            <h2 className='font-gilroy font-bold text-slate-900 text-2xl pl-4'>Edit Technical Specification</h2>
                        </div>
                        <div className='flex flex-row gap-2 items-center'>
                            <Button text="Cancel" onClick={navigateToView}/>
                            <Button text="Save" onClick={handleSave}/>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='mx-auto max-w-5xl mt-2 bg-white px-5 py-5 rounded-lg border-slate-300 border-2 shadow max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10 font-gilroy'>
                    {prdDetails ? (
                        <>
                            <div className="relative customMarkdown focus:outline-1 focus:ring focus:ring-blue">
                                <Editor
                                    ref={editorRef}
                                    editorState={editorState}
                                    // blockRendererFn={myBlockRenderer}
                                    onChange={handleEditorChange}
                                    onSelect={handleTextSelection}
                                    customStyleMap={getCustomStyleMap()}
                                />
                                {showPrompt && (
                                    <div className='fixed'
                                         style={{top: `${overlayPosition.top}px`, zIndex: 10, marginTop: -70}}>
                                        <div
                                            className='flex-col bg-white shadow border-slate-300 border-2 h-fit gap-3  rounded-lg w-[720px]'>
                                            <div className="flex items-center">
                                                <div
                                                    className='flex flex-row gap-3 justify-start hover:bg-slate-100 px-3 py-2 cursor-pointer' onClick={() => setShowAskAIOverlay(true)}>
                                                    <AiSparkIcon color='#2C55FB'/>
                                                    <button className='font-gilroy font-bold text-blue'
                                                    >Ask AI
                                                    </button>
                                                </div>
                                                <div className="border-l border-gray-300 border-[1px] h-[42px] "></div>
                                                <RichTextControls editorState={editorState}
                                                                  setEditorState={setEditorState}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showAskAIOverlay && (
                                    <div className='fixed'
                                         style={{top: `${overlayPosition.top}px`, zIndex: 10, marginTop: -70}}
                                         id="ask-ai-overlay">
                                        <div
                                            className='flex-col bg-white shadow border-slate-300 border-2 h-fit gap-3 px-3 py-3 rounded-lg w-[720px]'>
                                            <div
                                                className='absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none'>
                                                <AiSparkIcon color="#2C55FB"/>
                                            </div>
                                            <input
                                                ref={inputRef}
                                                className='rounded-lg w-full text-slate-900 outline-none pl-9'
                                                value={modification_request}
                                                placeholder="Ask AI to edit or generate (each change costs 2 credits)..."
                                                onChange={(e) => setPromptText(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmitPrompt();
                                                    }
                                                }}
                                            />
                                            <button onClick={handleSubmitPrompt}
                                                    className='absolute inset-y-0 right-6 items-center pl-3 '>
                                                <SendIcon/>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        "Loading..."
                    )}
                </div>
            </div>
        </SidebarLayout>
    );
}
