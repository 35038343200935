import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

interface MenuItemProps {
    name: string;
    href: string;
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    isOnSale?: boolean;
}

export default function MenuItem({ name, href, Icon, isOnSale }: MenuItemProps) {
    return (
        <NavLink
            to={href}
            className={({ isActive }) =>
                classNames("block w-[216px] h-10 rounded-md p-2 text-primary transition-all flex items-center", {
                    "bg-primary hover:bg-primary": isActive,
                    "bg-white hover:bg-secondary": !isActive,
                })
            }
        >
            {({ isActive }) => (
                <>
                    <Icon
                        className={classNames("h-6 w-6", {
                            "stroke-white": isActive,
                            "stroke-slate-900": !isActive,
                        })}
                        color={isActive ? '#ffffff' : '#0f172a'}
                    />
                    <p className={classNames("text-slate-500 font-normal text-sm font-gilroy pl-3 pr-16", {
                        "text-white text-sm font-normal font-gilroy": isActive,
                    })}>{name}</p>
                    {isOnSale && (
                        <span className="ml-2 px-2 py-1 text-xs font-gilroy font-semibold text-white bg-purple rounded-lg">
                            SALE
                        </span>
                    )}
                </>
            )}
        </NavLink>
    );
}
