export function AiSparkIcon(props:{color:string}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5001 4C10.7404 4.00003 10.9515 4.15933 11.0175 4.39038C12.0424 7.97813 13.1929 9.0056 16.6097 9.98252C16.8408 10.0486 17 10.2598 17 10.5001C17 10.7404 16.8407 10.9515 16.6096 11.0175C13.0225 12.0422 11.9936 13.1927 11.0175 16.6096C10.9515 16.8407 10.7403 17 10.5 17C10.2597 17 10.0485 16.8407 9.98248 16.6096C8.95759 13.0219 7.80709 11.9944 4.39026 11.0175C4.15923 10.9514 3.99997 10.7402 4 10.4999C4.00003 10.2596 4.15933 10.0485 4.39038 9.98249C7.97813 8.95759 9.0056 7.80708 9.98252 4.39026C10.0486 4.15923 10.2598 3.99997 10.5001 4Z"  fill={props.color}/>
            <path d="M17 14C17.1109 14 17.2084 14.0735 17.2389 14.1802C17.7119 15.8361 18.2429 16.3103 19.8199 16.7612C19.9265 16.7916 20 16.8891 20 17C20 17.1109 19.9265 17.2084 19.8198 17.2389C18.1642 17.7118 17.6894 18.2428 17.2389 19.8198C17.2084 19.9265 17.1109 20 17 20C16.8891 20 16.7916 19.9265 16.7611 19.8198C16.2881 18.1639 15.7571 17.6897 14.1801 17.2388C14.0735 17.2084 14 17.1109 14 17C14 16.8891 14.0735 16.7916 14.1802 16.7611C15.8361 16.2881 16.3103 15.7571 16.7612 14.1801C16.7916 14.0735 16.8891 14 17 14Z" fill={props.color}/>
        </svg>


    )
}
