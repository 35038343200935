import { FC } from "../../../types/general";
import { Navigate } from "react-router-dom";
import {AuthToken} from "../../../service/http/AuthToken";

export const PrivateLayout: FC = (props) => {
    const authToken = new AuthToken();

    if (!authToken.getAccessToken()) {
        return <Navigate to="/auth/login" />;
    }

    return <>{props.children}</>;
};
