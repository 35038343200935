import {useCallback} from 'react';
import axios from 'axios';
import {AuthToken} from "../http/AuthToken";

export const useAuth = () => {
    const authToken = new AuthToken();

    const logout = useCallback(async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/signout`, null, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken.getAccessToken()}`,
                },
            });
            authToken.clearTokens();
            // Navigate to sign-in page (use your routing logic here)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error logging out', error);
                // If you receive a 401 Unauthorized, consider the user as logged out
                if (error.response && error.response.status === 401) {
                    authToken.clearTokens();
                    // Navigate to sign-in page (use your routing logic here)
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }, [authToken.getAccessToken()]);

    const resetPassword = async (token: string, newPassword: string) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset/confirm`, {
                token,
                new_password: newPassword,
            });
            if (response.status === 200) {
                console.log('Password reset successful:', response.data);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return {
        logout,
        resetPassword,
    };
};
