import React from "react";
import {PaymentFailIcon} from "../../../components/icons/payment/PaymentFail";
import Button from "../../../components/base/Button";
import {Link} from 'react-router-dom';

export const PaymentFailed = () => {
    return (
        <div className="w-full h-full bg-white justify-center items-center flex flex-col">
            <div className="bg-white rounded-lg py-10 px-4 flex flex-col font-gilroy max-w-lg">
                <div className="flex flex-col justify-center items-center gap-4">
                    <PaymentFailIcon/>
                    <h3 className='font-bold text-2xl text-slate-800'>Payment Unsuccessful</h3>
                    <p className='text-base text-slate-800 text-center'>We’re having some trouble with your payment. Please try again.
                        If the problem persists, don't hesitate to <a className='underline' href='https://www.signlz.co/feedback' target='_blank' rel='noopener noreferrer'>contact us</a> for assistance. </p>
                    <Link to="/pricing">
                        <Button text="Retry Payment"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default PaymentFailed;
