interface PaymentFailIconProps {
    size?: number;
}

export function PaymentFailIcon({ size = 64 }: PaymentFailIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M6 32C6 17.64 17.64 6 32 6C46.36 6 58 17.64 58 32C58 46.36 46.36 58 32 58C17.64 58 6 46.36 6 32ZM32 22C32.5304 22 33.0391 22.2107 33.4142 22.5858C33.7893 22.9609 34 23.4696 34 24V34C34 34.5304 33.7893 35.0391 33.4142 35.4142C33.0391 35.7893 32.5304 36 32 36C31.4696 36 30.9609 35.7893 30.5858 35.4142C30.2107 35.0391 30 34.5304 30 34V24C30 23.4696 30.2107 22.9609 30.5858 22.5858C30.9609 22.2107 31.4696 22 32 22ZM32 44C32.5304 44 33.0391 43.7893 33.4142 43.4142C33.7893 43.0391 34 42.5304 34 42C34 41.4696 33.7893 40.9609 33.4142 40.5858C33.0391 40.2107 32.5304 40 32 40C31.4696 40 30.9609 40.2107 30.5858 40.5858C30.2107 40.9609 30 41.4696 30 42C30 42.5304 30.2107 43.0391 30.5858 43.4142C30.9609 43.7893 31.4696 44 32 44Z" fill="#EF3E27"/>
        </svg>

    )
}
