import lottieEmailAnimation from "../../assets/email_sent.json";
import Lottie from "lottie-react";
import {Link, useLocation} from 'react-router-dom';
import React from "react";

export const VerifyYourEmail = () => {
    const location = useLocation();
    const { email } = location.state || { email: 'joe@mailchimp.com' };
    return (
        <>
            <div className="w-full h-full bg-white justify-center items-center flex flex-col">
                <div className="max-w-2xl flex-col font-gilroy justify-start items-center gap-4 inline-flex">
                    <Lottie animationData={lottieEmailAnimation}/>
                    <div className="flex-col justify-start items-center gap-4 flex">
                        <div className="text-center text-slate-800 text-2xl font-bold leading-9">Verify Your Email</div>
                        <div className="text-center max-w-md"><span className="text-slate-800 text-xl font-normal leading-8">We've just sent an email to </span><span className="text-slate-800 text-xl font-bold leading-8">{email}</span><span className="text-slate-800 text-xl font-normal leading-8"> to verify your address. </span></div>
                    </div>
                    <div className="mt-10 flex flex-row justify-center">
                        <span className=" text-sm font-medium text-slate-800">
                            Already have an account?
                        </span>
                        <Link to="/auth/login" className="ml-1 text-sm font-bold text-brand-900 hover:underline">
                            Log In
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
