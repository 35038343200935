import React, { useState } from 'react';
import { ChevronUpIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

interface FAQItem {
    question: string;
    answer: string;
}

const FAQ_DATA: FAQItem[] = [
    {
        question: 'Q: Are the credits one-time purchases?',
        answer: 'A: Yes, the credits are one-time purchases that you can use whenever you need. There\'s no subscription commitment – you decide when to top up.',
    },
    {
        question: 'Q: How long are my credits valid for?',
        answer: 'A: Your credits have no expiration date – they\'ll remain in your account until you use them. This gives you the flexibility to use them whenever you have a project in mind.',
    },
    {
        question: 'Q: What happens if my credits run out mid-project?',
        answer: 'A: If your credits run low while you\'re in the middle of a project, don\'t worry! You can quickly top up your credits and continue seamlessly.',
    },
    {
        question: 'Q: Can I get a refund for unused credits?',
        answer: 'A: Refunds for unused credits are generally not provided, as we offer an initial 100 credits for free to ensure your satisfaction with the product. However, if you encounter technical issues or have a unique situation, please contact support@signlz.co for assistance.',
    },
];

const FAQ: React.FC = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const toggleExpand = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <div>
            {FAQ_DATA.map((item, index) => (
                <div key={index}>
                    <hr />
                    <div className='flex flex-row justify-between items-center cursor-pointer px-5 py-5' onClick={() => toggleExpand(index)}>
                        <h4 className='font-gilroy font-bold text-slate-900 text-base'>{item.question}</h4>
                        {expandedIndex === index ? (
                            <ChevronUpIcon className="h-4 w-4 stroke-slate-400 stroke-2" />
                        ) : (
                            <ChevronRightIcon className="h-4 w-4 stroke-slate-400 stroke-2" />
                        )}
                    </div>
                    {expandedIndex === index && <p className="font-gilroy font-normal text-slate-900 text-base px-5 pt-2 pb-5">{item.answer}</p>}

                </div>
            ))}
        </div>
    );
};

export default FAQ;
