import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import FeedbackButton from "../../components/base/FeedbackButton";
import { useNavigate } from 'react-router-dom';
import generatePRD from "../../components/icons/images/generate-prd.png"
import improvePRD from "../../components/icons/images/improve-prd.png"
import addFeature from "../../components/icons/images/add-feature.png"
import generateTechSpec from "../../components/icons/images/generate-techspec.png"
import generateTasks from "../../components/icons/images/generate-tasks.png"
// @ts-ignore
import mixpanel from "mixpanel-browser";
import {useEffect} from "react";


export function Home() {
    const navigate = useNavigate();

    const navigateToGeneratePRD = () => {
        navigate('/dashboard/generate-prd');
    };

    const navigateToImprovePRD = () => {
        navigate('/dashboard/improve-prd');
    };
    const navigateToAddFeature = () => {
        navigate('/dashboard/add-feature');
    };
    const navigateToGenerateTechSpec = () => {
        navigate('/dashboard/generate-techspec');
    };
    const navigateToGenerateTasks = () => {
        navigate('/dashboard/generate-tasks');
    };
    useEffect(() => {
        mixpanel.track("Dashboard Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);
    return (
        <SidebarLayout>
            <FeedbackButton/>
            <div className='mx-auto max-w-5xl mt-8 max-sm:px-4 max-md:px-4 max-lg:px-4'>
                <div className='flex flex-col gap-4 mb-10'>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h1 className='font-gilroy font-bold text-slate-900 text-2xl'>Welcome to Signlz</h1>
                            <p className='font-gilroy font-light text-slate-900 text-2xl'>Your AI-Powered Product Studio</p>
                        </div>
                    </div>
                    <div className="min-w-5xl h-44 justify-start items-start gap-4 inline-flex flex-wrap max-lg:min-w-full max-lg:flex-nowrap max-lg:flex-col max-lg:w-full">
                        <div onClick={navigateToGeneratePRD} className="w-80 h-fit px-5 py-5 bg-white rounded-lg shadow justify-start items-start flex max-lg:w-full cursor-pointer hover:ring-1 hover:ring-slate-300">
                            <div className="flex-col justify-start items-start gap-6 inline-flex">
                                <div className="justify-start items-start gap-2.5 inline-flex">
                                    <img alt='generate prd' className="w-9 h-9" src={generatePRD} />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 flex">
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="text-black text-xl font-bold font-gilroy leading-normal">Generate PRD</div>
                                    </div>
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="w-72 text-black text-base font-normal font-gilroy leading-normal max-lg:w-full">Generate structured and well defined product or feature requirements. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={navigateToImprovePRD} className="w-80 h-fit px-5 py-5 bg-white rounded-lg shadow justify-start items-start flex max-lg:w-full cursor-pointer hover:ring-1 hover:ring-slate-300">
                            <div className="flex-col justify-start items-start gap-6 inline-flex">
                                <div className="justify-start items-start gap-2.5 inline-flex">
                                    <img alt='improve prd' className="w-9 h-9" src={improvePRD} />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 flex">
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="text-black text-xl font-bold font-gilroy leading-normal">Improve Existing PRD</div>
                                    </div>
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="w-72 text-black text-base font-normal font-gilroy leading-normal max-lg:w-full">Improve your existing product requirements with a help of Signlz AI.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={navigateToAddFeature} className="w-80 h-fit px-5 py-5 bg-white rounded-lg shadow justify-start items-start flex max-lg:w-full cursor-pointer hover:ring-1 hover:ring-slate-300">
                            <div className="flex-col justify-start items-start gap-6 inline-flex">
                                <div className="justify-start items-start gap-2.5 inline-flex">
                                    <img alt='add feature to prd' className="w-9 h-9" src={addFeature} />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 flex">
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="text-black text-xl font-bold font-gilroy leading-normal">Add Feature to PRD</div>
                                    </div>
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="w-72 text-black text-base font-normal font-gilroy leading-normal max-lg:w-full">Let Signlz AI enrich your product requirements with new features.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={navigateToGenerateTechSpec} className="w-80 h-fit px-5 py-5 bg-white rounded-lg shadow justify-start items-start flex max-lg:w-full cursor-pointer hover:ring-1 hover:ring-slate-300">
                            <div className="flex-col justify-start items-start gap-6 inline-flex">
                                <div className="justify-start items-start gap-2.5 inline-flex">
                                    <img alt='add feature to prd' className="w-9 h-9" src={generateTechSpec} />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 flex">
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="text-black text-xl font-bold font-gilroy leading-normal">Generate Tech Spec</div>
                                    </div>
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="w-72 text-black text-base font-normal font-gilroy leading-normal max-lg:w-full">Generate technical specifications for your existing product requirements.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={navigateToGenerateTasks} className="w-80 h-fit px-5 py-5 bg-white rounded-lg shadow justify-start items-start flex max-lg:w-full cursor-pointer hover:ring-1 hover:ring-slate-300">
                            <div className="flex-col justify-start items-start gap-6 inline-flex">
                                <div className="justify-start items-start gap-2.5 inline-flex">
                                    <img alt='add feature to prd' className="w-9 h-9" src={generateTasks} />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 flex">
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="text-black text-xl font-bold font-gilroy leading-normal">Generate Tasks</div>
                                    </div>
                                    <div className="justify-start items-start gap-2.5 inline-flex">
                                        <div className="w-72 text-black text-base font-normal font-gilroy leading-normal max-lg:w-full">Generate tasks from your requirements with a help of Signlz AI.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </SidebarLayout>
    );
}
