export function userStoryIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4311_5037)">
                <rect width="24" height="24" rx="6" fill="#63BA3C"/>
                <path d="M15.6421 17.9989C15.5373 17.9992 15.436 17.9611 15.3574 17.8918L11.7853 14.7155L8.21324 17.8918C8.15142 17.9467 8.07503 17.9826 7.99327 17.9951C7.91151 18.0076 7.82788 17.9961 7.75248 17.9622C7.67707 17.9282 7.61311 17.8731 7.56831 17.8036C7.52351 17.7341 7.49979 17.6531 7.5 17.5704V7.71413C7.5005 7.25966 7.68125 6.82396 8.00261 6.5026C8.32396 6.18125 8.75967 6.0005 9.21413 6H14.3565C14.811 6.0005 15.2467 6.18125 15.568 6.5026C15.8894 6.82396 16.0701 7.25966 16.0706 7.71413V17.5704C16.0706 17.684 16.0255 17.793 15.9451 17.8734C15.8648 17.9537 15.7558 17.9989 15.6421 17.9989Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_4311_5037">
                    <rect width="24" height="24" rx="6" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
