import React, {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import axios from 'axios';
import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import {ArrowLeftIcon} from '@heroicons/react/24/outline';
import Button from "../../components/base/Button";
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import Lottie from "lottie-react";
import lottieAnimation from "../../assets/signlzLoader.json";
import Accordion from "../../components/base/Accordion";
import {CloseIcon} from "../../components/icons/close";
// @ts-ignore
import demoVideo from "../../assets/videos/TestVideoFinal.mp4"
import Banner from "../../components/base/Banner";

// @ts-ignore
import mixpanel from "mixpanel-browser";


type SubTask = {
    id: string;
    title: string;
    description: string;
};

type Story = {
    title: string;
    description: string;
    story_points?: number;
    'sub-tasks'?: SubTask[];
};

type Task = {
    title: string;
    description: string;
    user_stories?: Story[];
};

type CsvRecord = {
    Summary: string;
    'Issue id': string;
    'Issue Type': string;
    Description: string;
    'Parent id': string;
    'Story Points'?: number;
};

export function Tasks() {
    const {id} = useParams<{ id: string }>();
    const [prdDetails, setPrdDetails] = useState<any>(null);
    const authToken = new AuthToken();
    const [isLoading, setIsLoading] = useState(true);
    // const navigate = useNavigate();
    const [showTechSpec, setShowTechSpec] = useState(false);
    const [shouldPoll, setShouldPoll] = useState(true);
    // const [prdStatus, setPrdStatus] = useState<string | null>(null);
    let textToRender: JSX.Element[] = []; // Default text
    const [showPopup, setShowPopup] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const fetchPrdDetails = async () => {
            setIsLoading(true); // Set loading state to true before API call
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    setPrdDetails(response.data);
                    setShowTechSpec(response.data.prd.tasks.epics);
                }
            } catch (error) {
                console.error("Error fetching PRD details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPrdDetails();
    }, [id, authToken.getAccessToken()]);


    // Existing useEffect for polling
    useEffect(() => {
        const terminalStatuses = ['completed', 'duplicated', 'tasks_completed', 'initial_request_completed'];

        const pollPrds = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    // Before updating the details, check if tasks have been completed
                    if (prdDetails?.prd?.status !== 'tasks_completed' && response.data.prd.status === 'tasks_completed') {
                        // Send the success event to Mixpanel if the status has just changed to 'tasks_completed'
                        mixpanel.track("Tasks Generation Success", {
                            "PRD ID": id,
                        });
                    }
                    setPrdDetails(response.data);
                    if (terminalStatuses.includes(response.data.prd.status)) {
                        setShouldPoll(false);
                    }
                }
            } catch (error) {
                console.error("Error polling PRDs:", error);
            }
        };

        if (shouldPoll) {
            const intervalId = setInterval(() => {
                pollPrds();
            }, 10000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [shouldPoll, id, authToken.getAccessToken(), prdDetails?.prd?.status]);


// New useEffect to update showTechSpec
    useEffect(() => {
        if (prdDetails?.prd?.status === 'tasks_completed') {
            setShowTechSpec(true);
        }
    }, [prdDetails?.prd?.status]);

    if (!isLoading) {
        if (prdDetails?.prd?.status === 'tasks_completed') {
            if (
                prdDetails?.prd?.tasks.epics &&
                Array.isArray(prdDetails.prd?.tasks.epics) &&
                prdDetails.prd?.tasks.epics.length > 0
            ) {
                textToRender = prdDetails.prd.tasks.epics.map((task: any, index: number) => (
                    <Accordion task={task} key={index} index={index} epicName={task.title} />
                ));
            } else {
                textToRender = [<p>No tasks available.</p>];
            }
        }
    }

    const prepareDataForCsv = (epics: Task[]): CsvRecord[] => {
        const csvData: CsvRecord[] = [];
        let idCounter = 1;  // Initialize ID counter

        epics.forEach((epic) => {
            const epicId = idCounter++;  // Auto-generate Epic ID
            const epicRecord: CsvRecord = {
                Summary: epic.title,
                'Issue id': `E-${epicId}`,
                'Issue Type': 'Epic',
                Description: epic.description || '',
                'Parent id' : '',
            };
            csvData.push(epicRecord);

            epic.user_stories?.forEach((story) => {
                const storyId = idCounter++;  // Auto-generate Story ID
                const storyRecord: CsvRecord = {
                    Summary: story.title,
                    'Issue id': `S-${storyId}`,
                    'Issue Type': 'Story',
                    Description: story.description || '',
                    'Parent id': `E-${epicId}`,
                    'Story Points': story.story_points,
                };
                csvData.push(storyRecord);

                story['sub-tasks']?.forEach((subtask) => {
                    const subtaskId = idCounter++;  // Auto-generate Subtask ID
                    const subtaskRecord: CsvRecord = {
                        Summary: subtask.title,
                        'Issue id': `ST-${subtaskId}`,
                        'Issue Type': 'Subtask',
                        Description: subtask.description,
                        'Parent id': `S-${storyId}`,
                    };
                    csvData.push(subtaskRecord);
                });
            });
        });
        return csvData;
    };



    const generateCsv = (data: any[]): string => {
        const csvRows = [];
        let headers = Object.keys(data[0]);

        // Ensure 'Story Points' is included in the headers
        if (!headers.includes('Story Points')) {
            headers = [...headers, 'Story Points'];
        }

        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map((header) => {
                const escaped = ('' + (row[header] || '')).replace(/"/g, '\\"');  // Fallback to an empty string if the field is not present
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    };


    const downloadCsv = (csvContent: string, fileName: string): void => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGenerateTasks = async () => {
        // Initialize tech_spec to an empty string
        let tech_spec = "";

        // Check if tech_spec_versions is available
        const techSpecVersions = prdDetails?.prd?.tech_spec_versions;

        if (Array.isArray(techSpecVersions) && techSpecVersions.length > 0) {
            const lastIndex = techSpecVersions.length - 1;
            tech_spec = techSpecVersions[lastIndex]?.tech_spec_text;
        } else if (prdDetails?.prd?.tech_spec) {
            // If tech_spec_versions is not available, use tech_spec
            tech_spec = prdDetails.prd.tech_spec;
        } else if (prdDetails.prd.prd_text){
            tech_spec = prdDetails.prd.prd_text;
        }
        else {
            console.error('Both prdDetails.prd.tech_spec_versions and prdDetails.prd.tech_spec are undefined or empty.');
            return; // Exit the function as there's nothing to proceed with
        }

        // Proceed with your API call logic using the tech_spec value
        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${id}/generate_tasks`;

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    params: {
                        tech_spec,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                window.location.reload();
                mixpanel.track("Regenerate Tasks Start", {
                    "PRD ID": id,
                });
            } else {
                console.error('API did not return success.');
                mixpanel.track("Generate Tasks Start Failed", {
                    "PRD ID": id,
                    "Error": "API did not return success"
                });
            }
        } catch (error) {
            console.error('Error in API call:', error);
            setShowBanner(true);

        }
    };

    useEffect(() => {
        mixpanel.track("Tasks Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);
    return (
        <SidebarLayout>
            {showBanner && <Banner />}
            <FeedbackButton/>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50" onClick={closePopup}>
                    <div className="bg-white w-1/2 h-fit rounded-lg shadow-lg flex flex-col items-start justify-start p-4" onClick={(e) => e.stopPropagation()}>
                        <div className='flex flex-row w-full justify-between items-center'>
                            <h2 className="font-gilroy text-2xl font-bold">Transfer to Task Management Tool</h2>
                            <button onClick={closePopup}> <CloseIcon/> </button>
                        </div>

                        <p className="font-gilroy text-base text-slate-900 pt-4">To transfer all generated Epics, Stories, and Subtasks to your preferred task management tool, simply download them as a CSV file and then import the file into the tool of your choice. Below is an example video demonstrating how to import tasks into Jira.</p>
                        <div className="w-full h-full my-5 border-2 border-slate-200 rounded-lg">
                            <video src={demoVideo} controls loop playsInline id="video" preload="metadata"></video>


                        </div>
                        <Button
                            text='Download CSV'
                            onClick={() => {
                                const csvData = prepareDataForCsv(prdDetails.prd.tasks.epics);
                                const csvContent = generateCsv(csvData);
                                downloadCsv(csvContent, 'tasks.csv');
                                mixpanel.track('Download CSV', {
                                    'PRD ID': id,
                                });
                            }}
                        />
                    </div>
                </div>
            )}

            <div className='mx-auto max-w-5xl max-sm:px-4 h-20 z-10 bg-slate-100'>
                <div className='fixed py-5 bg-slate-100 z-10'>
                    <div className='w-[1028px] max-md:w-full max-md:gap-4 max-md:px-4 flex flex-row justify-between items-center'>
                        <div className='flex flex-row gap-2 items-center'>
                            <Link to={`/tech-spec/${id}`} className='flex flex-row gap-2 items-center'>
                                <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                                <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to Technical Specifications</h6>
                            </Link>
                        </div>
                        <div className='flex flex-row gap-2 items-center'>
                            { (prdDetails?.prd?.status === 'tasks_completed') && (
                                <Button text="Regenerate Tasks (10 Credits)" onClick={handleGenerateTasks}/>
                            ) }
                            <Button
                                text='Transfer to Task Managent Tool'
                                onClick={openPopup}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mx-auto max-w-5xl mt-2 bg-white mb-16 px-5 py-5 rounded-lg shadow max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10 font-gilroy'>
                    {prdDetails ? (
                        <>
                            {prdDetails?.prd?.status === "generating_tasks" ? (
                                <div className='flex flex-col'>
                                    <h1 className="text-3xl font-bold text-slate-800">Tasks Generation In Progress</h1>
                                    <p className='font-gilroy text-base text-slate-500'>While we're baking your task breakdown process, feel free to start working on another document. We'll notify you by email when it's ready.</p>
                                    <p className='font-gilroy font-bold text-base text-slate-600'>Could take from 30 secs up to 5 mins</p>
                                    <Lottie animationData={lottieAnimation}/>
                                </div>
                            ) : showTechSpec ? (
                                <>
                                    <h1 className="text-3xl font-bold text-slate-800">Backlog</h1>
                                    <div className="relative customMarkdown">
                                        {textToRender}
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        "Loading..."
                    )}
                </div>
            </div>

        </SidebarLayout>
    );
}
