import React, {useEffect, useState} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import SidebarLayout from "../../components/common/layouts/SidebarLayout";
import ReactMarkdown from 'react-markdown';
import {ArrowLeftIcon, Square2StackIcon, PencilSquareIcon, ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {Document, Packer, Paragraph, HeadingLevel} from "docx";
import Button from "../../components/base/Button";
import gfm from 'remark-gfm';
import FeedbackButton from "../../components/base/FeedbackButton";
import {AuthToken} from "../../service/http/AuthToken";
import Lottie from "lottie-react";
import lottieAnimation from "../../assets/signlzLoader.json";
import {CloseIcon} from "../../components/icons/close";
import Banner from "../../components/base/Banner";
// @ts-ignore
import mixpanel from "mixpanel-browser";

export function SingleTS() {
    const {id} = useParams<{ id: string }>();
    const [prdDetails, setPrdDetails] = useState<any>(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const authToken = new AuthToken();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [showTechSpec, setShowTechSpec] = useState(false);
    const [prdStatus, setPrdStatus] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [shouldPoll, setShouldPoll] = useState(true);
    const [showBanner, setShowBanner] = useState(false);

    const getButtonText = () => {
        if (prdStatus === 'tasks_completed') {
            return "View Tasks";
        } else if (prdStatus === 'generating_tasks') {
            return "Tasks Processing";
        } else {
            return "Generate Tasks (10 Credits)";
        }
    };

    const navigateToEdit = () => {
        if (prdStatus === 'generating_tasks') {
            setShowPopup(true);
        } else {
            navigate(`/tech-spec-edit/${id}`);
        }
    };
    const markdownToDocx = (markdownText: string) => {
        const lines = markdownText.split("\n");
        const children: any[] = [];

        lines.forEach((line) => {
            if (line.startsWith("##### ")) {
                children.push(new Paragraph({text: line.slice(6), heading: HeadingLevel.HEADING_5}));
            } else if (line.startsWith("#### ")) {
                children.push(new Paragraph({text: line.slice(5), heading: HeadingLevel.HEADING_4}));
            } else if (line.startsWith("### ")) {
                children.push(new Paragraph({text: line.slice(4), heading: HeadingLevel.HEADING_3}));
            } else if (line.startsWith("## ")) {
                children.push(new Paragraph({text: line.slice(3), heading: HeadingLevel.HEADING_2}));
            } else if (line.startsWith("# ")) {
                children.push(new Paragraph({text: line.slice(2), heading: HeadingLevel.HEADING_1}));
            } else {
                children.push(new Paragraph(line));
            }
        });

        return children;
    };

    const handleDownloadDocx = async () => {
        // Create a new document

        let latestTechSpecText = ""; // Initialize to an empty string

        // Fetch the latest tech_spec_text if available
        if (
            prdDetails?.prd?.tech_spec_versions &&
            Array.isArray(prdDetails.prd?.tech_spec_versions) &&
            prdDetails.prd?.tech_spec_versions.length > 0
        ) {
            const lastIndex = prdDetails.prd.tech_spec_versions.length - 1;
            latestTechSpecText = prdDetails.prd?.tech_spec_versions[lastIndex].tech_spec_text;
        } else {
            latestTechSpecText = prdDetails?.prd?.tech_spec;
        }
        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "defaultParagraph",
                        name: "Default Paragraph",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 14,
                            font: "Arial",
                        },
                    },
                ],
            },
            sections: [
                {
                    properties: {},
                    children: markdownToDocx(latestTechSpecText),
                },
            ],
        });

        // Generate docx file and trigger download
        const blob = await Packer.toBlob(doc);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Use the PRD title as the filename, if available
        link.download = prdDetails?.prd?.title ? `${prdDetails?.prd?.title}.docx` : 'tech_spec.docx';

        link.click();
        URL.revokeObjectURL(url);
        // Track the download event with Mixpanel
        mixpanel.track('TS Download', {
            'PRD ID': id,
        });
    };

    const handleCopyClick = async () => {
        try {
            let latestTechSpecText = ""; // Initialize to an empty string

            // Fetch the latest tech_spec_text if available
            if (
                prdDetails?.prd?.tech_spec_versions &&
                Array.isArray(prdDetails.prd?.tech_spec_versions) &&
                prdDetails.prd?.tech_spec_versions.length > 0
            ) {
                const lastIndex = prdDetails.prd.tech_spec_versions.length - 1;
                latestTechSpecText = prdDetails.prd?.tech_spec_versions[lastIndex].tech_spec_text;
            } else {
                latestTechSpecText = prdDetails?.prd?.tech_spec;
            }

            const title = prdDetails?.prd?.title;
            const fullTextToCopy = title ? `${title}\n\n${latestTechSpecText}` : latestTechSpecText;

            await navigator.clipboard.writeText(fullTextToCopy);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 3000);  // Hide the tooltip after 3 seconds
            // Track the copy event with Mixpanel
            mixpanel.track('TS Copy', {
                'PRD ID': id,
            });
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };


    const getPrdText = () => {
        if (prdDetails?.prd?.prd_text && !prdDetails?.prd?.versions) {
            return prdDetails?.prd?.prd_text;
        } else if (prdDetails?.prd?.versions && prdDetails.prd.versions.length > 0) {
            const lastIndex = prdDetails.prd.versions.length - 1;
            return prdDetails.prd.versions[lastIndex].prd_text;
        } else {
            return prdDetails?.prd?.generated_prd || "Loading...";
        }
    };
    // New function to get combined prd_text and added_feature
    const getCombinedPrdText = () => {
        const prdText = getPrdText();
        const addedFeature = prdDetails?.prd?.added_feature || "";
        return `${prdText}\n\n${addedFeature}`; // Combine with a newline in between
    };
    const retryTechSpecGeneration = async () => {
        let prd_text = getCombinedPrdText();

        // Check if prd_text is actually defined
        if (!prd_text) {
            console.error('The prd_text is undefined or null.');
            return; // Exit the function if prd_text is not available
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${id}/tech_spec/generate`;

        try {
            const response = await axios.post(
                apiUrl,
                {}, // POST request body, if any needed, should go here
                {
                    params: { prd_text }, // URL parameters should be passed in params object
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                console.log('Tech spec generation retry initiated successfully.');
                // Update state or UI here as needed
            } else {
                // Handle any non-200 responses here
                console.error('Tech spec generation retry did not return success.');
            }
        } catch (error) {
            console.error('Error retrying tech spec generation:', error);
        }
    };

    useEffect(() => {
        const fetchPrdDetails = async () => {
            setIsLoading(true); // Set loading state to true before API call
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    setPrdDetails(response.data);
                    setShowTechSpec(response.data.prd.tech_spec);
                    setPrdStatus(response.data.prd.status);
                }
            } catch (error) {
                console.error("Error fetching PRD details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPrdDetails().catch();
    }, [id, authToken.getAccessToken()]);

    useEffect(() => {
        const terminalStatuses = [
            'completed',
            'generating_tasks',
            'duplicated',
            'tasks_completed',
            'initial_request_completed',
            'tech_spec_completed'
        ];

        const pollPrds = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prd/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                });
                if (response.status === 200) {
                    setPrdDetails(response.data);
                    const status = response.data.prd.status;
                    console.log("Status is:", status);
                    if (status === 'tech_spec_generation_failed') {
                        await retryTechSpecGeneration();
                        // After a retry, you may want to wait for a longer interval before the next poll, or stop polling altogether
                    } else if (status === 'tech_spec_completed') {
                        setShouldPoll(false); // Stop further polling
                        // Send event to Mixpanel
                        mixpanel.track('TS Generation Success', {
                            'PRD ID': id,
                        });
                    } else if (terminalStatuses.includes(status)) {
                        setShouldPoll(false); // Stop further polling
                    }
                }
            } catch (error) {
                console.error("Error polling PRDs:", error);
            }
        };

        if (shouldPoll) {  // Check if should continue polling
            const intervalId = setInterval(() => {
                pollPrds().catch();
            }, 10000);  // 10 seconds

            return () => {
                clearInterval(intervalId);  // Cleanup
            };
        }
    }, [shouldPoll, id, authToken.getAccessToken()]);


    useEffect(() => {
        if (prdDetails?.prd?.status === 'tech_spec_completed') {
            setShowTechSpec(true);
        }
    }, [prdDetails?.prd?.status]);

    let textToRender = "Loading..."; // Default text

    if (!isLoading) {
        if (prdDetails?.prd?.status === 'tech_spec_completed' || prdDetails?.prd?.status === 'tasks_completed' || prdDetails?.prd?.status === 'generating_tasks') {
            if (
                prdDetails?.prd?.tech_spec_versions && // changed this line
                Array.isArray(prdDetails.prd?.tech_spec_versions) && // changed this line
                prdDetails.prd?.tech_spec_versions.length > 0 // changed this line
            ) {
                const lastIndex = prdDetails.prd.tech_spec_versions.length - 1; // changed this line
                textToRender = prdDetails.prd?.tech_spec_versions[lastIndex].tech_spec_text; // changed this line

            } else {
                textToRender = prdDetails?.prd?.tech_spec;
            }
        }
    }

    const handleGenerateTasks = async () => {
        // Initialize tech_spec to an empty string
        let tech_spec = "";

        // Check if tech_spec_versions is available
        const techSpecVersions = prdDetails?.prd?.tech_spec_versions;

        if (Array.isArray(techSpecVersions) && techSpecVersions.length > 0) {
            const lastIndex = techSpecVersions.length - 1;
            tech_spec = techSpecVersions[lastIndex]?.tech_spec_text;
        } else if (prdDetails?.prd?.tech_spec) {
            // If tech_spec_versions is not available, use tech_spec
            tech_spec = prdDetails.prd.tech_spec;
        } else if (prdDetails.prd.prd_text){
            tech_spec = prdDetails.prd.prd_text;
        }
        else {
            console.error('Both prdDetails.prd.tech_spec_versions and prdDetails.prd.tech_spec are undefined or empty.');
            return; // Exit the function as there's nothing to proceed with
        }

        // Proceed with your API call logic using the tech_spec value
        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${id}/generate_tasks`;

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    params: {
                        tech_spec,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate(`/tasks/${id}`);
                mixpanel.track("Generate Tasks Start", {
                    "PRD ID": id,
                });
            } else {
                console.error('API did not return success.');
            }
        } catch (error) {
            console.error('Error in API call:', error);
            setShowBanner(true);
        }
    };

    const handleButtonClick = () => {
        if (prdStatus === 'tasks_completed' ) {
            navigate(`/tasks/${id}`);
        } else if (prdStatus === 'generating_tasks') {
            // Do nothing or show a message if you want
        } else {
            handleGenerateTasks().catch();
        }
    };
    const onClose = () => {
        setShowPopup(false);
    };
    const handleDuplicatePRD = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prd/${id}/duplicate`,
                {},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                navigate('/my-prds');
            } else {
                console.error('API did not return success.');
            }
        } catch (error) {
            console.error('Error in API call:', error);
        }
    };

    const handleGenerateTS = async () => {
        // Initialize prd_text to null
        let prd_text = null;

        // Check if versions are available and non-empty
        if (
            prdDetails?.prd?.versions &&
            Array.isArray(prdDetails.prd.versions) &&
            prdDetails.prd.versions.length > 0
        ) {
            // Use the prd_text from the latest version
            const lastIndex = prdDetails.prd.versions.length - 1;
            prd_text = prdDetails.prd.versions[lastIndex].prd_text;
        } else if (prdDetails.prd.prd_text){
            prd_text = prdDetails.prd.prd_text;
        }

        else {
            // Fallback to generated_prd if versions are not available
            prd_text = prdDetails?.prd?.generated_prd;
        }

        // Ensure prd_text is not null or undefined before making the API call
        if (!prd_text) {
            console.error('No PRD text available to generate technical specification.');
            setShowBanner(true); // Show error banner if one exists
            return; // Exit the function if prd_text is not available
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/prd/${id}/tech_spec/generate`;
        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    params: {
                        prd_text,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken.getAccessToken()}`
                    }
                }
            );

            if (response.status === 200) {
                window.location.reload();
                mixpanel.track("Regenerate TS Start", {
                    "PRD ID": id,
                });
            } else {
                console.error('API did not return success.');
                setShowBanner(true); // Show error banner if one exists
                mixpanel.track("Generate TS Start Failed", {
                    "PRD ID": id,
                    "Error": "API did not return success"
                });
            }
        } catch (error) {
            console.error('Error in API call:', error);
            setShowBanner(true); // Show error banner if one exists
        }
    };
    useEffect(() => {
        mixpanel.track("TS Page Viewed", {
            "page": window.location.pathname,
            "title": document.title,
        });
    }, []);


    return (
        <SidebarLayout>
            {showBanner && <Banner />}
            <FeedbackButton/>
            <div className='mx-auto max-w-5xl max-sm:px-4 h-20 z-10 bg-slate-100'>
                <div className='fixed py-5 bg-slate-100 z-10'>
                    <div className='w-[1028px] max-md:w-full max-md:gap-4 max-md:px-4 flex flex-row justify-between items-center'>
                        <div className='flex flex-row gap-2 items-center'>
                            <Link to={`/prd/${id}`} className='flex flex-row gap-2 items-center'>
                                <ArrowLeftIcon className='w-4 h-4 text-slate-900 cursor-pointer'/>
                                <h6 className='font-gilroy font-medium text-slate-900 hover:underline'>Back to Product Requirements</h6>
                            </Link>
                        </div>
                        <div className='flex flex-row gap-2 items-center'>
                            { (prdDetails?.prd?.status === 'tech_spec_completed' || prdDetails?.prd?.status === 'tasks_completed') && (
                                <Button text="Regenerate TS (10 Credits)" onClick={handleGenerateTS} className='relative gradient-text'/>
                            ) }

                            { (prdDetails?.prd?.status === 'tech_spec_completed' || prdDetails?.prd?.status === 'tasks_completed' || prdDetails?.prd?.status === 'generating_tasks') && (
                                <Button text={getButtonText()} onClick={handleButtonClick}/>
                            ) }
                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={handleDownloadDocx}
                                 title='Download Docx'
                            >
                                <ArrowDownTrayIcon className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800" />
                            </div>
                            {/*<Button text="Download" onClick={handleDownloadDocx}/>*/}
                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={navigateToEdit}
                                 title='Edit Tech Specs'
                            >
                                <PencilSquareIcon className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800" />
                            </div>
                            {/*<Button text='Edit TS' onClick={navigateToEdit}/>*/}
                            {
                                showPopup && (
                                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70" onClick={onClose}>
                                        <div
                                            className="bg-white rounded-lg py-10 px-4 flex flex-col font-gilroy max-w-lg gap-6"
                                            onClick={(e) => e.stopPropagation()} // Prevents the popup from closing when clicking inside
                                        >
                                            <div className='flex flex-row justify-between w-full'>
                                                <h4 className='font-bold text-xl text-slate-800'>Editing Disabled</h4>
                                                <div className='w-6 h-6 hover:bg-slate-200 hover:rounded-full cursor-pointer' onClick={onClose}><CloseIcon/></div>
                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <p>Sorry, you can't edit this Tech Spec right now because a tasks are now being generated. Editing it could impact the itegrity, so we don't allow modifications at this point. However, you can duplicate the PRD to create a new version and start over everything.</p>
                                                <Button text="Duplicate PRD" onClick={handleDuplicatePRD}/>

                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                            <div className='px-2 py-2 rounded-lg bg-white shadow hover:bg-slate-300 cursor-pointer'
                                 onClick={handleCopyClick}
                                title='Copy Text'
                            >
                                <Square2StackIcon className="w-6 h-6 text-slate-800 cursor-pointer hover:text-slate-800"/>
                                {copySuccess && (
                                    <div
                                        className="absolute top-1 text-sm font-gilroy text-white bg-black px-3 py-1 rounded-lg shadow">
                                        Copied!
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mx-auto max-w-5xl mt-2 bg-white mb-16 px-5 py-5 rounded-lg shadow max-sm:px-4'>
                <div className='flex flex-col gap-4 mb-10 font-gilroy'>
                    {prdDetails ? (
                        <>
                            {prdDetails?.prd?.status === "tech_spec_generating" ? (
                                <div className='flex flex-col justify-center'>
                                    <h1 className="text-3xl font-bold text-slate-800">Technical Specification Generation In Progress</h1>
                                    <p className='font-gilroy text-base text-slate-500'>While we're baking your doc, feel free to start another task. We'll notify you by email when it's ready.</p>
                                    <p className='font-gilroy font-bold text-base text-slate-600'>Could take from 30 secs up to 5 mins</p>
                                    <Lottie animationData={lottieAnimation}/>
                                </div>
                            ) : showTechSpec ? (
                                <>
                                    <h1 className="text-3xl font-bold text-slate-800">Technical Specification Document</h1>
                                    <div className="relative customMarkdown">
                                        <ReactMarkdown remarkPlugins={[gfm]}>
                                            {textToRender}
                                        </ReactMarkdown>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        "Loading..."
                    )}
                </div>

            </div>
        </SidebarLayout>
    );
}
