import InputField from "../../components/base/InputField";
import {useParams, useNavigate} from "react-router-dom";
import authImg from "../../assets/images/auth.jpg";
import {useState} from "react";
import {AppRoutes} from "../../router";
import axios from 'axios';
import {SignlzLogoAlpha} from "../../components/icons/logoAlpha";

export const ResetPassword = () => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string | null>(null);  // New state for error message
    const params = useParams();
    const navigate = useNavigate();


    const handleSetPassword = async () => {
        const token = params.token;
        if (token) {
            if (password === confirmPassword) {
                setPasswordError(null);  // Clear any previous error

                // Making the API request to reset the password using axios
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset/confirm`, {
                        token: token,
                        new_password: password,
                    });

                    if (response.status === 200) {
                        // Handle the response data as needed
                        navigate(`${AppRoutes.AUTH_LOGIN}?passwordReset=true`);
                    } else {
                        // Handle API errors
                        setPasswordError(response.data.message || 'Failed to reset password');
                    }
                } catch (error) {
                    // Handle network errors
                    setPasswordError('Network error, please try again');
                }
            } else {
                setPasswordError("Passwords doesn't match");  // Set the error message
            }
        }
    };

    return (
        <div className="h-full w-full overflow-y-auto bg-white">
        <div className="font-gilroy flex h-full w-[35vw] items-center justify-center px-2 max-lg:w-full">
            {/* Set New Password section */}
            <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <div className='mb-24'><SignlzLogoAlpha/></div>
                <h1 className="mb-2.5 text-3xl font-bold  text-brand-900">
                    Set New Password
                </h1>
                {/* Password */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password"
                    placeholder="Min. 8 characters"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Confirm Password"
                    placeholder="Min. 8 characters"
                    id="confirm-password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {passwordError && <div className="font-gilroy text-sm text-red-500 mb-2">{passwordError}</div>}
                <button
                    onClick={handleSetPassword}
                    className="linear mt-2 w-full rounded-xl bg-slate-800 py-[12px] text-base font-semibold text-white transition duration-200 hover:bg-slate-600 active:bg-slate-700">
                    Set Password
                </button>
            </div>
            <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[65vw] 2xl:w-[65vw]">
                <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                />
            </div>
        </div>
        </div>
    );
}
