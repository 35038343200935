import React from "react";
import classNames from "classnames";
import {NavLink} from "react-router-dom";

interface SubMenuItemProps {
    name: string;
    href: string;
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export default function SubMenuItem({name, href, Icon}: SubMenuItemProps) {
    return (
        <NavLink
            to={href}
            target='_blank'
            rel='noopener noreferrer'
            className={({isActive}) =>
                classNames("block w-[216px] h-10 rounded-md p-2 text-primary transition-all", {
                    "bg-primary hover:bg-primary": isActive,
                    "bg-white hover:bg-secondary": !isActive,
                })
            }
        >
            {({isActive}) => (
                <>
                    <div className='flex flex-row gap-3 items-center'>
                        <Icon
                            className={classNames("h-6 w-6", {
                                "stroke-white": isActive,
                                "stroke-slate-900": !isActive,

                            })}
                            color={isActive?'#ffffff':'#9FB2CC'}
                        />
                        <p className={classNames("text-slate-500 font-normal text-sm font-gilroy", {
                            "text-white text-sm font-normal font-gilroy": isActive,
                        })}>{name} </p>
                    </div>
                </>
            )}
        </NavLink>
    );
}
