import axios from 'axios';
import { AuthToken } from './AuthToken';
export const initAxiosInterceptors = () => {
    const authToken: AuthToken = new AuthToken();
    axios.interceptors.request.use((config) => {
        const newConfig = { ...config };
        newConfig.url = `${config.url}`;
        if (authToken.getAccessToken() && authToken.getRefreshToken() && newConfig.headers) {
            newConfig.headers.Authorization = `Bearer ${authToken.getAccessToken()}`;
        }
        return newConfig;
    });
    axios.interceptors.response.use((response) => {
        if (response.data.tokens) {
            authToken.setTokens(response.data.tokens);
        }
        return response;
    }, (error) => {
        if (error.response) {
            const originalRequest = error.config;
            if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}/token/refresh?refresh_token=${authToken.getRefreshToken()}`) {
                if (error.response.data.detail === 'Invalid refresh token') {
                    authToken.clearTokens();
                    window.location.reload();
                }
            }
            const refreshToken = authToken.getRefreshToken();
            if (error.response.status === 401 && originalRequest.url !== `${process.env.REACT_APP_API_URL}/token` && refreshToken) {
                return axios.post(`${process.env.REACT_APP_API_URL}/token/refresh?refresh_token=${refreshToken}`, null, {
                    headers: {
                        'Accept': 'application/json',
                    },
                }).then((res) => {
                    authToken.setTokens({accessToken:res.data.access_token, refreshToken:res.data.refresh_token});
                    window.location.reload();
                    return axios(originalRequest);
                })
            }
            return Promise.reject(error);
        }
    });
};
