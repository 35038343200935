import axios from 'axios';
import {useParams, useNavigate} from "react-router-dom";
import {AppRoutes} from "../../router";
import {useEffect} from "react";

export const VerifyEmail = () => {
    const {token} = useParams();
    const navigate = useNavigate();

    const handleVerifyEmail = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/verify-email`, null, {
                params: { token },
            });
            navigate(`${AppRoutes.AUTH_LOGIN}?emailVerified=true`);
        } catch (error) {
            navigate(`${AppRoutes.AUTH_LOGIN}?emailVerified=false`);
        }
    };

    useEffect(() => {
        handleVerifyEmail().catch();
    });

    return (
        <>
        </>
    );
};

