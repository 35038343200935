import InputField from "../../components/base/InputField";
import {Link, Navigate} from "react-router-dom";
import React, {useState} from 'react';
import authImg from "../../assets/images/auth.jpg";
import axios from 'axios';
import {SignlzLogoAlpha} from "../../components/icons/logoAlpha";
// @ts-ignore
import mixpanel from "mixpanel-browser";
import {FcGoogle} from "react-icons/fc";

export const SignUp = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [fullname, setFullName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null); // New state for messages
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Reset message
        setMessage(null);

        // Check required fields
        if (!fullname.trim()) {
            setMessage("Full name is required.");
            return;
        }
        if (!email.trim()) {
            setMessage("Email is required.");
            return;
        }
        if (!password.trim()) {
            setMessage("Password is required.");
            return;
        }

        // Proceed with sign up
        handleSignUp().catch();
    };

    const handleGoogleSignUp = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/google/login`);
            const url = response.data.url;
            window.location.href = url; // Redirect the user to the received URL
        } catch (error) {
            console.error("Error during Google sign up:", error);
            // Handle errors (e.g., show a message to the user)
        }
    };

    const handleSignUp = async () => {
        setIsLoading(true);
        setMessage(null); // Clear any previous messages

        const requestBody = {
            username: email,
            email: email,
            password: password,
            full_name: fullname
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                setShouldNavigate(true);
                // Identify the user with Mixpanel
                mixpanel.identify(email); // Use the email as a unique identifier for the user

                // Track the sign-up event with Mixpanel
                mixpanel.track('Sign Up', {
                    'Username': email, // Assuming username is the email
                    'Email': email,
                    'Full Name': fullname,
                    'Signup Type': 'Standard' // Adjust the value as needed
                });
            } else {
                setMessage(response.data.message || "User with this email exists.");
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                // Check if the error response has the specific 'detail' message
                const backendDetail = error.response.data.detail;
                if (backendDetail === "Username already exists") {
                    setMessage("User with this email already exists.");
                } else {
                    // Fallback error message for other types of errors
                    setMessage("There was an error signing up. Please try again.");
                }
            } else {
                // Handle non-Axios errors
                setMessage("An unexpected error occurred. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }


    };
    return (
        <div className="h-full w-full overflow-y-auto bg-white">
            {shouldNavigate && <Navigate to="/verify-your-email" state={{ email: email }} />}
            <div className="font-gilroy flex h-full w-[35vw] items-center justify-center px-2 max-lg:w-full">
                {/* Sign Up section */}
                <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <div className='mb-12'><SignlzLogoAlpha/></div>
                    <h1 className="mb-2.5 text-3xl font-bold  text-brand-900">
                        Create Account
                    </h1>
                    <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-lg bg-white border border-brand-300 hover:cursor-pointer hover:bg-slate-200" style={{ boxShadow: "0px 1px 3px 0 rgba(51,65,85,0.1)" }} onClick={handleGoogleSignUp} >
                        <div className="rounded-full text-xl">
                            <FcGoogle />
                        </div>
                        <h2 className="text-base font-gilroy font-semibold text-slate-900 hover:bg-slate-200">
                            Sign Up with Google
                        </h2>
                    </div>
                    <div className="mb-6 flex items-center  gap-3">
                        <div className="h-px w-full bg-gray-200" />
                        <p className="text-base text-gray-600"> or </p>
                        <div className="h-px w-full bg-gray-200" />
                    </div>
                    <form onSubmit={handleFormSubmit}>
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Full Name"
                            placeholder="Your Full Name"
                            id="name"
                            type="text"
                            value={fullname}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Email"
                            placeholder="youremail@gmail.com"
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        {/* Password */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Password"
                            placeholder="Min. 8 characters"
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button
                            type='submit'
                            disabled={isLoading}
                            className="linear mt-2 w-full rounded-xl bg-slate-800 py-[12px] text-base font-semibold text-white transition duration-200 hover:bg-slate-600 active:bg-slate-700 ">
                            {isLoading ? 'Signing Up...' : 'Create Your Account'}
                        </button>
                        {/* Show message if there's any */}
                        {message && (
                            <div
                                className={`mt-4 text-sm font-gilroy ${message === "Your account has been successfully created. To activate it we've sent you an email" ? "text-slate-900" : "text-red-500"}`}
                            >
                                {message}
                            </div>
                        )}


                    </form>
                    <div className="mt-12 flex flex-row justify-center">
                        <span className=" text-sm font-medium text-slate-800">
                            Already have an account?
                        </span>
                        <Link to="/auth/login" className="ml-1 text-sm font-bold text-brand-900 hover:underline">
                            Log In
                        </Link>
                    </div>
                </div>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[65vw] 2xl:w-[65vw]">
                    <div
                        className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                        style={{backgroundImage: `url(${authImg})`}}
                    />
                </div>
            </div>
        </div>
    );
}
