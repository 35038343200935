import React, { useState } from 'react';
import Button from './Button'
import {Link} from 'react-router-dom';
import {CloseIcon} from "../icons/close";

interface BannerProps {
    onClose?: () => void;
}

const Banner: React.FC<BannerProps> = ({ onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div className={`relative px-4 mx-2 mt-2 h-14 bg-yellow-400 rounded-lg flex items-center justify-between transition-all duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className='w-6'></div>
            <div className="text-black font-bold flex flex-row justify-center items-center gap-4">
                <p className='font-gilroy font-normal'><span className='font-bold'>Low on credits!</span> Refuel to ignite your PRD magic!</p>
                <Link to='/pricing'>
                <Button text='Get More Credits'/>
                </Link>
            </div>
            <div className="flex items-center">
                <button onClick={handleClose} className="text-black hover:bg-slate-900/10 hover:rounded-full">
                    <CloseIcon/>
                </button>
            </div>
        </div>
    );
};

export default Banner;
