import {BoltIcon, CurrencyDollarIcon,} from "@heroicons/react/24/outline";
import {NavLink, useNavigate} from "react-router-dom";
import MenuItem from "./MenuItem";
import SubMenuItem from "./SubMenuItem";
import {AppRoutes} from "../../../router";
import {SecondaryButton} from "../../base/SecondaryButton";
import {ProfileIcon} from "../../icons/profile";
import {useAuth} from "../../../service/hooks/useToken";
import {AiSparkIcon} from "../../icons/aiSpark";
import {BoardIcon} from "../../icons/board";
import ProgressBar from "../../../components/base/ProgressBar";
import {LogoutIcon} from "../../icons/logout";
import {BugIcon} from "../../icons/bugIcon";
import {FeatureIcon} from "../../icons/featureIcon";
import React from "react";
import {SignlzLogoAlpha} from "../../icons/logoAlpha";


export interface SidebarProps {
}

const menuItems = [
    {name: "AI Dashboard", href: AppRoutes.HOME_INDEX, icon: AiSparkIcon},
    {name: "My PRDs", href: AppRoutes.MY_PRDS_INDEX, icon: BoardIcon},
    {name: "Pro Tips", href: AppRoutes.PRO_TIPS_INDEX, icon: BoltIcon},
    {name: "Pricing", href: AppRoutes.PRICING_INDEX, icon: CurrencyDollarIcon},

];

const subMenuItems = [
    {name: "Report a Bug", href: "https://signlz.canny.io/bugs", icon: BugIcon},
    {name: "Request a Feature", href: "https://signlz.canny.io/feature-requests", icon: FeatureIcon},

];


// Updated this line to remove empty object pattern
const Sidebar: React.FC<SidebarProps> = () => {
    const {logout} = useAuth();
    const navigate = useNavigate();
    const handleLogOut = () => {
        logout().then(() => {
            navigate("/auth/login");
        });
    };

    return (
        <div className="flex flex-col w-[240px]">
            <div className="flex min-h-0 flex-1 flex-col bg-white">
                <div className="flex flex-1 flex-col overflow-y-auto">
                    <div className="flex flex-row gap-3 flex-shrink-0 items-center m-3 px-3">
                        <NavLink to="/dashboard">
                            <SignlzLogoAlpha/>

                        </NavLink>
                    </div>
                    <nav className="flex-1 m-3" aria-label="Sidebar">
                        <div className="flex flex-col space-y-3">
                            {menuItems.map((item) => (
                                // @ts-ignore
                                <MenuItem key={item.href} name={item.name} href={item.href} Icon={item.icon} isOnSale={item.isOnSale}/>

                            ))}

                        </div>
                    </nav>
                </div>
                <div className="w-full">
                    <div className="px-3 pb-6 flex flex-col items-center justify-between">
                        <div className="flex flex-col space-y-3 pb-4">
                            {subMenuItems.map((item) => (
                                // @ts-ignore
                                <SubMenuItem key={item.href} name={item.name} href={item.href} Icon={item.icon}/>
                            ))}


                            <hr></hr>
                        </div>

                        <div className='flex flex-row items-center gap-2'>
                            <div className='flex flex-row items-center gap-2'>
                                <ProfileIcon/>
                                <ProgressBar/>
                            </div>
                            <SecondaryButton variant="default" className="w-10 rounded-full p-0 ml-4"
                                             onClick={handleLogOut}>
                                <LogoutIcon/>
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
