// @ts-ignore
import {EditorState, RichUtils, Modifier} from "draft-js";
import { useState } from "react";
import {UnorderedListIcon} from "../icons/unorderedlist";
import {OrderedListIcon} from "../icons/orderedlist";


type RichTextControlsProps = {
    editorState: EditorState,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
};



export const RichTextControls: React.FC<RichTextControlsProps> = ({ editorState, setEditorState }) => {
    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setURLValue] = useState("");

    const toggleBold = () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    const toggleItalic = () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    const toggleUnderline = () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    const toggleOrderedList = () => setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
    const toggleUnorderedList = () => setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));



    // Function to change font size
    const changeFontSize = (fontSize: string) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const nextContentState = Modifier.applyInlineStyle(
            contentState,
            selection,
            `FONTSIZE-${fontSize}`
        );
        const nextEditorState = EditorState.push(editorState, nextContentState, "change-inline-style");
        setEditorState(nextEditorState);
    };
    const addLink = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

        // Apply the entity to the selected text
        setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
        setURLValue('');
        setShowURLInput(false);
    };

    return (
        <div className="flex flex-row font-gilroy pl-2">
            <button className='font-bold hover:bg-slate-100 h-[42px] px-3' onClick={() => changeFontSize('32')}>H1</button>
            <button className='font-bold hover:bg-slate-100 h-[42px] px-3' onClick={() => changeFontSize('24')}>H2</button>
            <button className='font-bold hover:bg-slate-100 h-[42px] px-3' onClick={() => changeFontSize('18')}>H3</button>
            <button className='font-bold hover:bg-slate-100 h-[42px] px-3' onClick={() => changeFontSize('14')}>H4</button>
            <button className='hover:bg-slate-100 h-[42px] px-3' onClick={() => changeFontSize('16')}>Paragraph</button>
            <button className='font-bold hover:bg-slate-100 h-[42px] px-3' onClick={toggleBold}>B</button>
            <button className='hover:bg-slate-100 h-[42px] px-3' onClick={toggleItalic}><em>i</em></button>
            <button className='underline hover:bg-slate-100 h-[42px] px-3' onClick={toggleUnderline}>U</button>
            <button className='hover:bg-slate-100 h-[42px] px-3' onClick={toggleUnorderedList}><UnorderedListIcon/></button>
            <button className='hover:bg-slate-100 h-[42px] px-3' onClick={toggleOrderedList}><OrderedListIcon/></button>
            <button className='hover:bg-slate-100 h-[42px] px-3' onClick={() => setShowURLInput(true)}>Link</button>

            {showURLInput && (
                <div className="absolute top-20 items-center bg-white shadow-sm rounded-lg border-2 border-slate-400 px-4 py-4">
                    <div className='flex flex-row gap-3'>
                    <input
                        className="border rounded h-[32px] px-2 w-80"
                        type="text"
                        placeholder="Paste link"
                        value={urlValue}
                        onChange={(e) => setURLValue(e.target.value)}
                    />
                    <button className="bg-slate-900 text-white rounded-lg hover:bg-slate-100 h-[32px] px-3" onClick={addLink}>Add</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export const getCustomStyleMap = () => {
    return {
        'FONTSIZE-32': {
            fontSize: '32px',
            fontWeight: 'bold',
        },
        'FONTSIZE-24': {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        'FONTSIZE-18': {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        'FONTSIZE-14': {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        'FONTSIZE-16': {
            fontSize: '16px',
            fontWeight: '400',
        },
    };
};
