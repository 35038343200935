import InputField from "../../components/base/InputField";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import authImg from "../../assets/images/auth.jpg";
import axios from 'axios';
import {AuthToken} from "../../service/http/AuthToken";
import {SignlzLogoAlpha} from "../../components/icons/logoAlpha";
import { FcGoogle } from "react-icons/fc";
// @ts-ignore
import mixpanel from "mixpanel-browser";

export const SignIn = () => {
    const isValidEmail = (email:any) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleSignIn().catch();
    };

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const passwordReset = queryParams.get('passwordReset');
    const emailVerified = queryParams.get('emailVerified');

    const authToken = new AuthToken();

    const navigate = useNavigate();
    let from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (isAuthenticated) {
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, navigate, from]);

    const handleSignIn = async () => {
        setLoading(true);
        setError(null);

        // Check for blank fields
        if (!email.trim() || !password.trim()) {
            setError('Both email and password are required.');
            setLoading(false);
            return;
        }

        // Check for valid email
        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            // Prepare form data for the sign-in request
            const formData = new URLSearchParams();
            formData.append('username', email);
            formData.append('password', password);

            // Make the sign-in API request
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/token`, formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            // Check the sign-in response
            if (response.status === 200 || response.status === 201) {
                authToken.setTokens({
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                });
                localStorage.setItem('email', email);
                setIsAuthenticated(true);

                // Fetch the user data from the API
                const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
                    headers: {
                        'Authorization': `Bearer ${response.data.access_token}`,
                    },
                });

                // Now you have user data, you can send it to Mixpanel
                const userData = userDataResponse.data;

                // Use a unique identifier for the user here, such as a user ID
                const userId = userData.id; // Make sure you have an 'id' field in the user data

                // Identify the user with Mixpanel
                mixpanel.identify(userId);

                // Set the user's profile in Mixpanel
                mixpanel.people.set({
                    '$email': email,
                    '$last_login': new Date(),
                    '$name': userData.full_name, // Make sure the user data has 'full_name'
                    '$balance': userData.balance // and 'balance' fields
                });

                // Track the sign-in event with Mixpanel
                mixpanel.track('Sign In', {
                    'Email': email,
                    'Full Name': userData.full_name
                });
            } else {
                setError('Email or password is wrong.');
            }
        } catch (err) {
            console.error('An error occurred during sign in:', err);
            setError('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };
    const handleGoogleSignIn = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/google/login`);
            const url = response.data.url;
            window.location.href = url; // Redirect the user to the received URL
        } catch (error) {
            console.error("Error during Google sign up:", error);
            // Handle errors (e.g., show a message to the user)
        }
    };


    return (
        <>{isAuthenticated?
                <Navigate to="/dashboard" state={{ from: location }} replace /> :
            <div className="h-full w-full overflow-y-auto bg-white">
                <div className="font-gilroy flex h-full w-[35vw] items-center justify-center px-2 max-lg:w-full">
                    {/* Sign in section */}
                    <div className="mt-[5vh] w-full max-w-full flex-col justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <div className='mb-24'>  <SignlzLogoAlpha/> </div>
                        <h1 className="mb-2.5 text-3xl font-bold  text-brand-900">
                            Log In
                        </h1>
                        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-lg bg-white border border-brand-300 hover:cursor-pointer hover:bg-slate-200" style={{ boxShadow: "0px 1px 3px 0 rgba(51,65,85,0.1)" }} onClick={handleGoogleSignIn}>
                            <div className="rounded-full text-xl">
                                <FcGoogle />
                            </div>
                            <h2 className="text-base font-gilroy font-semibold text-slate-900 hover:bg-slate-200">
                                Sign In with Google
                            </h2>
                        </div>
                        <div className="mb-6 flex items-center  gap-3">
                            <div className="h-px w-full bg-gray-200" />
                            <p className="text-base text-gray-600"> or </p>
                            <div className="h-px w-full bg-gray-200" />
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <InputField
                                variant="auth"
                                extra="mb-3"
                                label="Email"
                                placeholder="Email Address"
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    const emailValue = e.target.value;
                                    setEmail(emailValue);
                                    if (!isValidEmail(emailValue)) {
                                        setError('Please enter a valid email address.');
                                    } else {
                                        setError(null);
                                    }
                                }}
                            />

                        {/* Password */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Password"
                            placeholder="Your Password"
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                            {/* Display error message */}
                            {error && <div className="mb-4 font-gilroy text-sm text-red-500">{error}</div>}
                        <div className="mb-4 flex items-center justify-between px-2">
                            <div className="flex items-center">
                            </div>
                            <Link to='/auth/forgot-password' className="text-sm font-medium text-brand-500 hover:underline">
                                Forgot Password?
                            </Link>
                        </div>

                            <button
                                type='submit'
                                className="linear mt-2 w-full rounded-lg bg-slate-800 py-[12px] text-base font-semibold text-white transition duration-200 hover:bg-slate-600 active:bg-slate-700"
                                disabled={loading}
                            >
                                {loading ? 'Signing In...' : 'Sign In'}
                            </button>
                        </form>
                        {passwordReset === 'true' && (
                            <div className="font-gilroy text-sm text-slate-900 mt-4">
                                Your password was successfully changed.
                            </div>
                        )}
                        {emailVerified === 'true' && (
                            <div className="font-gilroy text-sm text-slate-900 mt-4">
                                Your email was successfully verified.
                            </div>
                        )}
                        {emailVerified === 'false' && (
                            <div className="font-gilroy text-sm text-slate-900 mt-4">
                                Email wasn't verified, <a href='https://signlz.co/contact' target='_blank' rel='noopener noreferrer'> <span className='font-gilroy underline'> contact our support.</span></a>
                            </div>
                        )}
                        <div className="mt-12 flex flex-row justify-center">
          <span className=" text-sm font-medium text-slate-800">
            Not registered yet?
          </span>
                            <Link to="/auth/signup" className="ml-1 text-sm font-bold text-brand-900 hover:underline">
                                Create an account
                            </Link>
                        </div>
                    </div>
                    <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[65vw] 2xl:w-[65vw]">
                        <div
                            className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                            style={{ backgroundImage: `url(${authImg})` }}
                        />
                    </div>
                </div>
            </div>
        }
        </>
    );
}
